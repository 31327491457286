import * as React from "react";
import Avatar from '@mui/material/Avatar';

import {deepOrange} from '@mui/material/colors';
import { Profile } from "../../modules/profile/ProfileTypes";
import {useTranslation} from "react-i18next";

export interface ProfileAvatarProps {
    profile: Profile
}

export const ProfileAvatar: React.FC<ProfileAvatarProps> = ({profile}) => {
    const {t} = useTranslation()

    const myStyles = {
        avatar: {
            margin: '2px 10px',
        },
        orangeAvatar: {
            margin: '2px 10px',
            color: '#fff',
            backgroundColor: deepOrange[500],
        }
    };


        const style = profile.isPrimary ? myStyles.orangeAvatar : myStyles.avatar;
        let avatar = profile.avatar;
        let avatarNumber = profile.avatarNumber;
        return (<Avatar sx={style}>
            {avatar.indexOf("|tfrsc") < 0 ? avatar : t("resource." + avatar) + " " + avatarNumber}
        </Avatar>)

}