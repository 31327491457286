import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import * as React from "react";
import styles from "./OrderMenu.module.css";
import {MenuType, FoodItem, ItemPortion, ExtraItem, Nutrient} from "../../modules/order/OrderTypes";
import {useTranslation} from "react-i18next";
import CustomMenuComponent from "./customMenu/CustomMenuComponent";
import DailyMenuComponent from "./dailyMenu/DailyMenuComponent";
import NutrientDialog from "./NutrientDialog";
import Box from "@mui/material/Box";


export interface TabMenuProps {
    firstTitle: string;
    secondTitle: string;
    information: string;
    dietDescription: string;
    tabChanger: any;
    activeTab: MenuType;
    isDirty: boolean;
    mealMomentIsBlocked: boolean;
    isBBF: boolean;
    isBBFReadonly: boolean;
    foodItems: { [key: number]: FoodItem };
    foodItemsPortions: { [key: number]: ItemPortion };
    foodItemsExtra: { [key: number]: ExtraItem };
    noMeal: boolean
}

const TabMenuComponent: React.FC<TabMenuProps> = ({
                                                      activeTab,
                                                      tabChanger,
                                                      firstTitle,
                                                      secondTitle,
                                                      isDirty,
                                                      mealMomentIsBlocked,
                                                      information,
                                                      dietDescription,
                                                      isBBF,
                                                      isBBFReadonly,
                                                      foodItemsPortions,
                                                      foodItemsExtra,
                                                      foodItems,
                                                      noMeal
                                                  }) => {
    const {t} = useTranslation();
    const menuActiveTab = isBBF && isBBFReadonly ? 1 : activeTab;
    const [openNutrients, setOpenNutrients] = React.useState(false)
    const [sortedSelectedFoodItemsNutrients, setSortedSelectedFoodItemsNutrients] = React.useState<Nutrient[]>([])
    const [incompleteNutrients, setIncompleteNutrients] = React.useState(false)
    // const [test, setTest] = React.useState('0')

    const tmpSelectedFoodItemsNutrients: Nutrient[] = [];

    const handleClickOpenNutrients = () => {
        calculateNutrientDialog(foodItems, foodItemsPortions, foodItemsExtra);
    };

    const handleCloseNutrients = () => {
        setOpenNutrients(false)
        setSortedSelectedFoodItemsNutrients([])
    };


    const calculateNutrient = (nutrient: Nutrient, foodItemId: any, portion: number) => {
        let uniqueNutrient = nutrient.code.trim() + nutrient.unit.trim();
        const index = tmpSelectedFoodItemsNutrients && tmpSelectedFoodItemsNutrients.length > 0 ? tmpSelectedFoodItemsNutrients.findIndex(x => (x.code.trim() + x.unit.trim()) === uniqueNutrient) : -1;
        if (index > -1) {
            let nut: Nutrient = {
                code: nutrient.code,
                id: nutrient.id,
                name: nutrient.name,
                sortOrder: nutrient.sortOrder,
                totCalculatedValuePerPortion: nutrient.totCalculatedValuePerPortion,
                totCalculatedWeightPerPortion: nutrient.totCalculatedWeightPerPortion,
                unit: nutrient.unit,
                value: tmpSelectedFoodItemsNutrients[index].value + nutrient.value,
                totCalculatedValue: tmpSelectedFoodItemsNutrients[index].totCalculatedValue + (nutrient.totCalculatedValuePerPortion * portion)
            }
            tmpSelectedFoodItemsNutrients.splice(index, 1);
            tmpSelectedFoodItemsNutrients.push(nut);
        }
        else {
            let nut: Nutrient = {
                code: nutrient.code,
                id: nutrient.id,
                name: nutrient.name,
                sortOrder: nutrient.sortOrder,
                totCalculatedValuePerPortion: nutrient.totCalculatedValuePerPortion,
                totCalculatedWeightPerPortion: nutrient.totCalculatedWeightPerPortion,
                unit: nutrient.unit,
                value: nutrient.value,
                totCalculatedValue: (nutrient.totCalculatedValuePerPortion * portion)
            }
            tmpSelectedFoodItemsNutrients.push(nut);
        }
        setSortedSelectedFoodItemsNutrients(tmpSelectedFoodItemsNutrients.sort((n1: Nutrient, n2: Nutrient) => n1.sortOrder - n2.sortOrder))
    }

    const calculateNutrientDialog = (foodItemsData: { [key: number]: FoodItem }, foodItemsPortionsData: { [key: number]: ItemPortion }, foodItemsExtraData: { [key: number]: ExtraItem }) =>
    {
        setIncompleteNutrients(false);

        tmpSelectedFoodItemsNutrients.splice(0);

        if (foodItemsPortionsData) {
            for (const foodItemId in foodItemsPortionsData) {
                if (!foodItemsData[foodItemId] || (!foodItemsData[foodItemId].isNonFood && foodItemsData[foodItemId].nutrientsList.length === 0)) {
                    setIncompleteNutrients(true)
                    // setTest(foodItemId)
                }
                let weight = foodItemsData[foodItemId] && foodItemsData[foodItemId].nutrientsList.find(n => n.totCalculatedWeightPerPortion === 0);
                if (foodItemsData[foodItemId] && !foodItemsData[foodItemId].isNonFood && weight !== undefined) {
                    setIncompleteNutrients(true)
                    // setTest(foodItemId)
                }

                foodItemsData[foodItemId] && foodItemsData[foodItemId].nutrientsList.forEach((nutrient) => {
                    if (nutrient.id < 0) {
                        setIncompleteNutrients(true)
                        calculateNutrient(nutrient, foodItemId, foodItemsPortionsData[foodItemId].portion)
                    }
                    else {
                        calculateNutrient(nutrient, foodItemId, foodItemsPortionsData[foodItemId].portion)
                    }
                })
            }
        }

        if (foodItemsExtraData) {
            for (const foodItemId in foodItemsExtraData) {
                if (!foodItemsExtraData[foodItemId].isNonFood && foodItemsExtraData[foodItemId].nutrientsList.length === 0) {
                    setIncompleteNutrients(true)
                }
                let weight = foodItemsExtraData[foodItemId].nutrientsList.find(n => n.totCalculatedWeightPerPortion === 0);
                if (!foodItemsExtraData[foodItemId].isNonFood && weight !== undefined) {
                    setIncompleteNutrients(true)
                }

                foodItemsExtraData[foodItemId].nutrientsList.forEach((nutrient) => {
                    if (nutrient.id < 0) {
                        setIncompleteNutrients(true)
                        calculateNutrient(nutrient, foodItemId, foodItemsExtraData[foodItemId].portion);
                    }
                    else {
                        calculateNutrient(nutrient, foodItemId, foodItemsExtraData[foodItemId].portion);
                    }
                })
            }
        }

        if (sortedSelectedFoodItemsNutrients.length > 0) {
            setOpenNutrients(true)
        }

    }

    React.useEffect(() => {
        if (sortedSelectedFoodItemsNutrients.length > 0) {
            setOpenNutrients(true)
        }
    }, [sortedSelectedFoodItemsNutrients])


    return <>
        <Paper elevation={1} square={true}>
            <Tabs
                value={menuActiveTab}
                onChange={tabChanger}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth">
                <Tab label={TabLabel(firstTitle, false)} tabIndex={MenuType.DAILY_MENU}
                     disabled={isBBF && isBBFReadonly}/>
                <Tab label={TabLabel(secondTitle, isDirty)} tabIndex={MenuType.CUSTOM_MENU}
                     disabled={mealMomentIsBlocked}/>
            </Tabs>
        </Paper>
        <Box className={styles.container}>
            { (<Box className={styles.information}>
                <NutrientDialog handleCloseNutrients={handleCloseNutrients} openNutrients={openNutrients} sortedSelectedFoodItemsNutrients={sortedSelectedFoodItemsNutrients} incompleteNutrients={incompleteNutrients} />
                <IconButton aria-label="info" className={styles.infoIcon}
                            onClick={handleClickOpenNutrients}>
                    <InfoIcon/>
                </IconButton>
                <Box className={styles.informationText}>
                    <Typography variant="caption">
                        {information}
                    </Typography>
                    {dietDescription && <Typography variant="caption" className={styles.diet}>
                        {t('menu.MmogelijkeKeuzes')} {dietDescription.toLowerCase()}
                    </Typography>}
                </Box>
            </Box>)}

            {MenuType.DAILY_MENU === menuActiveTab && <DailyMenuComponent/>}
            {MenuType.CUSTOM_MENU === menuActiveTab && <CustomMenuComponent/>}
        </Box>
    </ >


}

const TabLabel = (string: string, isBold: boolean) => {
    return <Typography variant="subtitle1" id="title"
                       className={isBold ? styles.tabLabelBold : styles.tabLabel}>
        {string}
    </Typography>
}

export default TabMenuComponent