import * as React from "react";
import {useTranslation} from "react-i18next";
import ConfirmDialog from "../dialog/ConfirmationDialog";

interface ConfirmationDialogProp {
    openLogout: boolean
    setOpenLogout: (openLogout: boolean) => void
    setOpenMenu: (open: boolean) => void
    logout: () => void
}

const ConfirmationDialog: React.FC<ConfirmationDialogProp> = ({openLogout, setOpenLogout, setOpenMenu, logout}) => {
    const {t} = useTranslation();

    const handleCloseLogout = () => {
        setOpenLogout(false)
        setOpenMenu(false)
    };

    const handleLogout = () => {
        setOpenLogout(false)
        logout();
    };

    return <ConfirmDialog open={openLogout} handleClose={handleCloseLogout} title=''
                          message={t("algemeen.AfmeldenText")}
                          closeText={t("algemeen.Annuleren")} handleConfirm={handleLogout}
                          confirmText={t("algemeen.Afmelden")}
    />
}

export default ConfirmationDialog