// configureStore.js
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from "redux-thunk";
import {reducers } from './store';

export default function configureStore(preloadedState: any) {

    var devTools =
        process.env.NODE_ENV !== 'production' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__();

    console.log("devTools to load:");
    console.log(devTools);

    if (devTools) {
        return createStore(
            reducers, // root reducer with router state
            preloadedState,
            compose(
                applyMiddleware(
                    thunk // for dispatching history actions
                    // ... other middlewares ...
                ),
                devTools
            ),
        );
    } else {
        return createStore(
            reducers, // root reducer with router state
            preloadedState,
            compose(
                applyMiddleware(
                    thunk // for dispatching history actions
                    // ... other middlewares ...
                ),
            ),
        );
    }

    
}