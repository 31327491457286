import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styles from "./LoginComponent.module.css";
import {useTranslation} from 'react-i18next';
import {ConfirmLogonExpired} from "../../modules/auth/authActions";
import Box from "@mui/material/Box";

const ExpiredComponent: React.FC = () => {
    const {t} = useTranslation();
    const login = () => {
        ConfirmLogonExpired()()
    }

    return <Box className={styles.loginContainer}>
        <Typography variant="h5" gutterBottom>
            {t("expired.Expired")}
        </Typography>
        <Button className={styles.loginButton} variant="contained" size="large" color="primary"
                onClick={() => login()}>
            {t("expired.Login")}
        </Button>
    </Box>;

}

export default ExpiredComponent