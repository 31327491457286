import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


interface ConfirmDialogProps{
    open: boolean
    handleClose: () => void
    closeText: string
    message: string
    handleConfirm?: () => void
    confirmText?: string
    title?: string
}
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({open, handleClose, handleConfirm, closeText, confirmText, message, title}) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="confirm-dialog"
            aria-describedby="confirm-dialog">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {closeText}
                </Button>
                {handleConfirm && confirmText && (
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        {confirmText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog