import React from 'react'
import {Components, createTheme, ThemeOptions, Theme} from '@mui/material/styles';


declare module '@mui/material/styles' {
    interface Theme {
        topHeader: {
            main: React.CSSProperties["color"]
        },
        informationHeader: {
            main: React.CSSProperties["color"]
        },
        overviewHeader: {
            main: React.CSSProperties["color"]
        },
        mealMoment: {
            main: React.CSSProperties["color"],
            active: React.CSSProperties["color"]

        },
        cancelComponents: {
            main: React.CSSProperties["color"],
            contrastText: React.CSSProperties["color"],
        },
        logo: string,
    }
    interface ThemeOptions {
        topHeader?: {
            main?: React.CSSProperties["color"]
        },
        informationHeader?: {
            main?: React.CSSProperties["color"]
        },
        overviewHeader?: {
            main?: React.CSSProperties["color"]
        },
        mealMoment?: {
            main?: React.CSSProperties["color"],
            active?: React.CSSProperties["color"]
        },
        cancelComponents?: {
            main?: React.CSSProperties["color"],
            contrastText?: React.CSSProperties["color"],
        },
        logo?: string,
    }
}

export const getTheme = (options: ThemeOptions): Partial<{ components: Components<Theme>}> => {
    return createTheme(options);
}
