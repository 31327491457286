import {Drawer} from "@mui/material";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import styles from "./Header.module.css";
import Box from "@mui/material/Box";
import {Language, Profile} from "../../modules/profile/ProfileTypes";
import i18n from "i18next";

interface LanguageDrawerProps {
    openLanguages: boolean
    setOpenLanguages: (openLanguages: boolean) => void
    configLanguages?: any
    profiles?: Profile[]
    cmealLanguages?: Language[]
    activeProfile: Profile
    saveProfile: (patientNumber: string, isDisclaimerChecked: boolean, preferredDietNumber: number, preferredLanguageNumber: number, isUsingApp: boolean, newCulture: string, profile: Profile) => void;
}

const LanguageDrawer: React.FC<LanguageDrawerProps> = ({openLanguages, setOpenLanguages, configLanguages, profiles, cmealLanguages, activeProfile, saveProfile}) => {
    let langKeys = Object.keys(configLanguages);
    let langNames: string[] = Object.values(configLanguages);
    // let langNamesImg: string[] = [];
    // langNames.map((key: string, t) => {
    //     langNamesImg.push("../../../images/" + key.trim() + ".png");
    // })

    const handleLanguageChanged = (preferredLanguageCulture: string) => {
        if (profiles.length > 0) {
            let ap: Profile;
            let preferredLanguageId: number = 0;

            for (let i = 0; i < profiles.length; i++) {
                if (profiles[i].id === activeProfile.id) {
                    if (cmealLanguages && cmealLanguages.length > 0) {
                        preferredLanguageId = cmealLanguages.find(x => x.culture.trim().toUpperCase() === preferredLanguageCulture.trim().toUpperCase()).id;
                    }
                    profiles[i].isUsingApp = activeProfile.isUsingApp;
                    profiles[i].preferredLanguage = preferredLanguageId;
                    profiles[i].preferredLanguageCulture = preferredLanguageCulture;

                    ap = profiles[i];
                }
            }

            saveProfile(ap.id, true, ap.preferredDiet, ap.preferredLanguage, !ap.isUsingApp, preferredLanguageCulture, ap);
        }

        i18n.changeLanguage(preferredLanguageCulture);
        setOpenLanguages(false)
    };

    return (<Drawer anchor="right" open={openLanguages} onClose={() => setOpenLanguages(false)}>

            <Box className={styles.menu}>
                {langKeys.map((key: string, i) => {
                    return <MenuItem key={key} value={key} onClick={() => handleLanguageChanged(key)}>
                        <Box component={'img'} src={require("../../images/" + langNames[i].trim() + ".png")}
                             sx={{maxWidth: '10%', marginRight: '5px'}}/>
                        <ListItemText>{langNames[i]}</ListItemText>
                    </MenuItem>
                })}
            </Box>
        </Drawer>
    )
}

export default LanguageDrawer
