import { Property } from 'csstype';

export interface FoodItem {
    id: number;
    description: string;
    possiblePortions: number[];
    category?: string;
    menuChartLineNr?: number;
    courseGroupId : number;
    orderKey: string;
    allergens: boolean,
    allergensList: Allergen[],
    nutrients: boolean,
    nutrientsList: Nutrient[],
    image_url: string,
    detailedDescription: string,
    isNonFood: boolean,
    hideForApp: boolean,
}


export interface FoodMenu {
    courses: FoodCourse[],
}

export interface FoodCourse {
    id: number;
    title: string;
    sortOrder: number;
    isExceeded?: boolean;
    courseGroups: number[];
}

export interface FoodCourseGroup {
    blocked: any;
    id: number;
    courseID: number;
    title: string;
    caption: string;
    foodItems: number[];
    discriminationSetNumber: number;
    isExceeded?: boolean;
    sortOrder: number;
    nutritionNr: number;
}

export interface PatientMealMoments {
    pid: string;
    mealDays: MealDay[],
    firstAvailableMealDayIndex: number
}

export interface MealDay {
    mealDate: string;
    mealMoments: MealMoment[];
}

export interface OrderItem {
    orderKey: string,
    portion: number;
}

export interface ItemPortion {
    foodItemID: number,
    menuChartLineNr: number,
    portion: number;
}

export interface ExtraItem {
    id: number,
    description: string,
    portion: number,
    allergens: boolean,
    allergensList: Allergen[],
    nutrients: boolean,
    nutrientsList: Nutrient[],
    image_url: string,
    detailedDescription: string,
    isNonFood: boolean,
}

export enum MenuType {
    DAILY_MENU,
    CUSTOM_MENU,
}

export interface MealMomentDiet {
    dietNr: number;
    dietDescription: string;
    nutritionNr: number;
    nutritionDescription: string;
}

export interface MealMoment {
    id: number;
    title: string;
    description: string;
    date: string;
    dayName: string;
    menuChart: number;
    toggle: boolean;
    isOrdered: boolean;
    reason: string;
    tabIndex: number;
    mealMomentKey: string;
    isNoMeal: boolean;
    blocked: boolean;
    isBBF: boolean;
    isBBFSelectable: boolean;
    diet: MealMomentDiet;
}

export const initialOrderState: OrderState = {
    mealMoment: null,
    menuType: MenuType.DAILY_MENU,
    expandedCoursePanel: 0,
    itemPortions: {},
    foodItemsExtra: {},
    snackbar: {
        show: false,
        text: "",
        variant: "normal",
        duration: 10000,
        textAlign: "center"
    },
    overviewPanelOpen: false,
    customDialog: {
        show: false,
        title: "",
        content: "",
        buttonText: "",
    },
    foodInformationDialog: {
        show: false,
        description: '',
        allergens: null,
        nutrients: null,
        url_image: '',
        detailedDescription: '',
    },
    isDirty: false
}

export interface OrderState {
    mealMoment: MealMoment;
    menuType: MenuType;
    expandedCoursePanel: number;
    itemPortions: { [key: number]: ItemPortion };
    foodItemsExtra: { [key: number]: ExtraItem };
    snackbar: SnackbarStatus;
    customDialog: CustomDialogStatus;
    foodInformationDialog: FoodItemDialogStatus;
    overviewPanelOpen: boolean;
    isDirty: boolean;
}

export interface SnackbarStatus {
    show: boolean,
    text: string,
    variant: string,
    duration?: number,
    textAlign: Property.TextAlign
}

export interface CustomDialogStatus {
    show: boolean,
    title: string,
    content: string,
    buttonText: string,
}

export interface FoodItemDialogStatus {
    show: boolean,
    description: string,
    allergens: Allergen[],
    nutrients: Nutrient[],
    url_image: string,
    detailedDescription: string,
}

export interface Allergen {
    type: string,
    name: string,
}

export interface Nutrient {
    id: number,
    name: string,
    unit: string,
    value: number,
    code: string,
    totCalculatedWeightPerPortion: number,
    totCalculatedValuePerPortion: number,
    sortOrder: number,
    totCalculatedValue: number,
}

export interface SelectMenuOptions {
    menuType: MenuType,
    focusCourse: number;
    focusCourseGroup: number;
}