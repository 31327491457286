import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import { MenuState, initialMenuState } from "../modules/menu/MenuTypes";
import * as Menu from "../modules/menu/MenuReducer";
import * as Order from "../modules/order/OrderReducer";
import * as Profile from "../modules/profile/ProfileReducer";
import * as Config from "../modules/config/ConfigReducer";
import { OrderState, initialOrderState } from "../modules/order/OrderTypes";
import { AuthState } from "../modules/auth/authTypes";
import * as Auth from "../modules/auth/AuthReducer";
import { actionsEnum } from "../modules/auth/authActions";
import { DiscriminationState, initialDiscriminationState } from '../modules/order/discriminition/discriminationTypes';
import { initialProfileState, ProfileState } from '../modules/profile/ProfileTypes';
import { ConfigState, initialConfigState } from '../modules/config/ConfigTypes';
import {discriminationReducer} from "../modules/order/discriminition/discriminationReducer";

// The top-level state object
export interface ApplicationState {
    menu: MenuState;
    order: OrderState;
    discrimination : DiscriminationState; 
    auth: AuthState;
    profile: ProfileState;
    config: ConfigState;
}

export const initialApplicationState: ApplicationState = {
    menu: initialMenuState,
    order: initialOrderState,
    discrimination : initialDiscriminationState, 
    auth: null,
    profile: initialProfileState,
    config: initialConfigState,
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type. 
const baseReducer = combineReducers<ApplicationState>({
    auth: Auth.authReducer,
    discrimination: (state = initialDiscriminationState) => { return state; },
    order: Order.orderReducer, 
    menu: Menu.menuReducer,
    profile: Profile.profileReducer,
    config: Config.configReducer,
});

//export const reducers = reduceReducers<ApplicationState>(baseReducer, discriminationReducer);
const innerReducer = reduceReducers<ApplicationState>(baseReducer, discriminationReducer);

export const reducers = (state: ApplicationState, action: any) => {
    switch (action.type) {
        case actionsEnum.LOGOUT:
        case actionsEnum.LOGON_USER:
                let initialNewState : ApplicationState = {
                    menu: initialMenuState,
                    order: initialOrderState,
                    discrimination: initialDiscriminationState,
                    auth: null,
                    profile: initialProfileState,
                    config: state.config
                }
            return innerReducer(initialNewState, action);  
        case actionsEnum.LOGON_EXPIRED:
            let expiredState: ApplicationState = {
                menu: initialMenuState,
                order: initialOrderState,
                discrimination: initialDiscriminationState,
                auth: {
                    ...state.auth,
                    isExpired : true
                },
                profile: initialProfileState,
                config: state.config
            }
            return innerReducer(expiredState, action);
        default:
            return innerReducer(state, action);
    }
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}



