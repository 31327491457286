import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styles from "./MealMoment.module.css";
import MealMomentButtonComponent from "./MealMomentButtonComponent";
import * as React from "react";
import {MealDay, MealMoment} from "../../../modules/order/OrderTypes";
import {Profile} from "../../../modules/profile/ProfileTypes";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

interface FoodTabProps {
    day: MealDay
    index: any
    mealDayIndex: number
    setAllMealsBlocked: (allMealsBlocked: boolean) => void
    profile: Profile
    setOpenSelectProfile: (openSelectProfile: boolean) => void
    hasMultipleProfiles: boolean
    selectMealMoment: (mealMoment: MealMoment) => void;
}

const FoodTab: React.FC<FoodTabProps> = ({
                                             day,
                                             index,
                                             mealDayIndex,
                                             setAllMealsBlocked,
                                             profile,
                                             setOpenSelectProfile,
                                             hasMultipleProfiles,
                                             selectMealMoment
                                         }) => {
    const {t} = useTranslation();

    const handleClickOpenSelectProfile = () => {
        setOpenSelectProfile(true)
    };

    React.useEffect(() => {
        let selectedDay = mealDayIndex === index ? day : null;
        if (selectedDay != null) {
            setAllMealsBlocked(selectedDay.mealMoments.findIndex(mm => mm.blocked === false) < 0 || selectedDay.mealMoments.findIndex(mm => mm.toggle === true) < 0)
            selectedDay = null;
        }
    }, [mealDayIndex, index, day, setAllMealsBlocked])


    const title = <Typography
        sx={{overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap", fontWeight: "bold", color: "black"}}>
        {profile && profile.name ? profile.name : t('meal.Mmaaltijd')}
    </Typography>


    return <Box className={styles.tab} hidden={mealDayIndex !== index} key={index}>
        <Box sx={{mt: '5px'}}>
            {!hasMultipleProfiles ? title : <Button onClick={handleClickOpenSelectProfile}>
                {title}
                <ArrowDropDownIcon/>
            </Button>}
        </Box>

        <Box sx={{display: 'inline-grid'}}>
            {day.mealMoments.map((mealMoment: MealMoment, i) =>
                <MealMomentButtonComponent selectMealMoment={selectMealMoment} data={mealMoment} key={i}
                                           logo={i}/>)
            }
        </Box>

    </Box>
}

export default FoodTab