import * as React from "react";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import styles from "./DailyMenu.module.css";
import {ExtraItem} from "../../../modules/order/OrderTypes";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import Box from "@mui/material/Box";
import AllergenButton from "../AllergenButton";

interface FoodItemExtraProps {
    id: number
    divider?: boolean
}

const FoodItemExtraComponent: React.FC<FoodItemExtraProps> = ({divider, id}) => {
    const {t} = useTranslation()
    const myOrderState = useSelector((state: ApplicationState) => state.order)
    let foodItemExtra: ExtraItem = myOrderState.foodItemsExtra[id];
    let itemPortion = foodItemExtra?.portion;
    const description = foodItemExtra?.description
    const portion = itemPortion ? itemPortion : 0
    const allergens = foodItemExtra?.allergens
    const allergensList = foodItemExtra?.allergensList
    const image_url = foodItemExtra?.image_url
    const detailedDescription = foodItemExtra?.detailedDescription
    const nutrients = foodItemExtra.nutrients
    const nutrientList = foodItemExtra.nutrientsList

    return (<>
        <ListItem>
            <Typography className={styles.portionContainer} variant="body2"><strong>{portion}</strong></Typography>
            <Typography id={styles.caption} variant="caption">{t("algemeen.Portie")}</Typography>
            <Box className={styles.foodContainer}>
                <Typography variant="body2"><strong>{description}</strong></Typography>
            </Box>
            {(allergens && allergensList.length > 0) || (nutrients && nutrientList.length > 0) || detailedDescription !== null ? <AllergenButton  allergensList={allergensList} description={description} detailedDescription={detailedDescription} image_url={image_url} nutrientList={nutrientList}/> : null}
        </ListItem>
        {divider && <Divider/>}
    </>)
}

export default FoodItemExtraComponent