import * as React from "react";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import styles from "./FoodItem.module.css";
import {FoodCourseGroup, FoodItem, MenuType} from "../../modules/order/OrderTypes";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {selectMenu} from "../../modules/order/OrderActions";
import Box from "@mui/material/Box";
import AllergenButton from "./AllergenButton";

export interface FoodItemOwnProps {
    id: number;
    amount: number;
    showDivider: boolean;
    mealMomentIsBlocked: boolean;
    showNutrientIcon: boolean;
}

const getCategory = (foodItemID: number, courseGroups: { [key: number]: FoodCourseGroup }) => {
    for (const key in courseGroups) {
        if (courseGroups[key].foodItems.find(x => x === foodItemID)) {
            return courseGroups[key].title;
        }
    }
}

const FoodItemComponent: React.FC<FoodItemOwnProps> = ({ id, amount, showDivider, mealMomentIsBlocked, showNutrientIcon }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch()

    const myMenuState = useSelector((state: ApplicationState) => state.menu)
    const foodItem: FoodItem = myMenuState.menu.foodItems[id];

    const description = !foodItem ? '' : foodItem.description
    const category = !foodItem ? '' : getCategory(id, myMenuState.menu.courseGroups)
    const allergens = !foodItem ? false : foodItem.allergens
    const allergensList = !foodItem ? [] : foodItem.allergensList
    const image_url = foodItem?.image_url
    const courseGroupId = !foodItem ? 0 : foodItem.courseGroupId
    const detailedDescription = !foodItem ? '' : foodItem.detailedDescription
    const nutrients = !foodItem ? false : foodItem.nutrients
    const nutrientList = !foodItem ?  [] : foodItem.nutrientsList

    console.log(nutrients, nutrientList)

    const openInCustomMenu = (courseGroupId: number) => {
        selectMenu(MenuType.CUSTOM_MENU, null, courseGroupId)(dispatch, myMenuState)
    }

    return (<>
        <ListItem>
            <Typography className={styles.portionContainer} variant="body2"><strong>{amount}</strong></Typography>
            <Typography id={styles.caption} variant="caption">{t("algemeen.Portie")}</Typography>

            <Button sx={{justifyContent: 'flex-start', color: theme => theme.palette.text.primary}} onClick={() => mealMomentIsBlocked ? false : openInCustomMenu(courseGroupId)}
                    className={styles.foodContainer} disabled={mealMomentIsBlocked}>
                <Box>
                    <Typography variant="caption">{category}</Typography>
                    <Typography variant="body2"><strong>{description}</strong></Typography>
                </Box>
            </Button>
            {showNutrientIcon && ((allergens && allergensList.length > 0) || (nutrients && nutrientList.length > 0) || detailedDescription !== null) ? <AllergenButton  allergensList={allergensList} description={description} detailedDescription={detailedDescription} image_url={image_url} nutrientList={nutrientList}/> : null}
        </ListItem>
        {showDivider && <Divider/>}
    </>)
}

export default FoodItemComponent