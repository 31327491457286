import React, {Suspense} from 'react';
import { createRoot } from "react-dom/client";
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import axios from "axios";
import "moment/locale/nl";
import "moment/locale/fr";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import 'typeface-roboto';
import configureAxios from './configureAxios';
import configureStore from './configureStore';
import './csp';
import {getTheme} from "./css/theme";
import i18n from "./i18n";
import './index.css';
import {preflightSession} from './modules/auth/authActions';
import {receiveConfig} from './modules/config/ConfigActions';
import {StatePersistance} from './statePersistance';
import {ApplicationState} from './store';
import { RoutesComponent } from "./RouterComponent";

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

const initialState: ApplicationState = StatePersistance.loadState("cmeal.state", {});

const store = configureStore(initialState);
store.subscribe(() => { //save state to localstorage
    StatePersistance.saveState("cmeal.state", store.getState());
});

configureAxios(store);

async function loadTheme() {
    return await axios.get("/hstatic/theme.json", {}).then(response => {
        return getTheme(response.data);
    }).catch(error => {
        console.log(error);
    })
}

async function loadHospitalConfiguration()  {
    return await axios.get("/api/hospital/config")
        .then(async response => {
            store.dispatch(receiveConfig(response.data));
            if (response.data.translationActive) {
                let found = false;
                if (i18n.language === undefined) {
                    i18n.changeLanguage(response.data.defaultLanguage);
                } else {
                    for (const key in response.data.languages) {
                        if (i18n.language === key) {
                            found = true;
                        }
                    }
                    if (!found) {
                        i18n.changeLanguage(response.data.defaultLanguage);
                    }
                }
            } else {
                i18n.changeLanguage("nl");
            }
            return response.data;
        }).catch(error => {
            //show error page
            console.log(error);
            initialState.config.appOffline = true;
        });
}

const cache = createCache({
    key: 'my-prefix-key',
    //nonce: nonce,
    prepend: true,
});

    const root = createRoot(document.getElementById("root"));
    async function renderApp(theme: any) {
        return root.render(
            <CacheProvider value={cache}>
                <CssBaseline/>
                {
                    <Provider store={store}>
                        <I18nextProvider i18n={i18n}>
                            <Suspense fallback={<div>loading</div>}>
                                <ThemeProvider theme={theme}>
                                    <RoutesComponent />
                                </ThemeProvider>
                            </Suspense>
                        </I18nextProvider>
                    </Provider>
                }
            </CacheProvider>
        )
    }

async function main() {
    console.log('main begin');
    const theme = await loadTheme();
    console.log('theme loaded');
    const config = await loadHospitalConfiguration();
    console.log('hospital configuration loaded');
    await preflightSession(store);
    console.log('session preflight');

    await renderApp(theme);
    console.log('app rendered');

    store.dispatch(receiveConfig(config));
}

(async () => main())();

//main().then(() => {

//// If you want your app to work offline and load faster, you can change
//// unregister() to register() below. Note this comes with some pitfalls.
//// Learn more about service workers: https://bit.ly/CRA-PWA
//    //console.log('unregistered');
//    //serviceWorker.unregister();

//});