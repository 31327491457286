
export interface DiscriminationConfiguration {
    rules: { [key: number]: DiscriminationRule };
    rulesList : number[];
    exclusions: { [key: number]: DiscriminationSetExclusion };
    exclusionsList: number[];
    exclusionsFoodItemSetList: { [key: number]: DiscriminationFoodItemSetExclusion };
}

export interface DiscriminationState  {
    configuration: DiscriminationConfiguration;
    ruleByFoodItem: { [foodItemId: number]: number[] };
    ruleByFoodItemSet: { [foodItemId: number]: number[] };
    rulePortionCounter: { [ruleId: number]: number };
    ruleTypeCounter: { [ruleId: number]: number };
    courseGroupSets: { [courseGroup: number]: number[] }
}

export const initialDiscriminationState: DiscriminationState = {
    configuration: {
        rules: {},
        rulesList : [],
        exclusions: {},
        exclusionsList: [],
        exclusionsFoodItemSetList: []
    },
    ruleByFoodItem: {},
    ruleByFoodItemSet: {},
    rulePortionCounter: {},
    ruleTypeCounter: {},
    courseGroupSets: {}
}

export interface DiscriminationLock {
    foodItemId : number;
    reason: string;
}

export interface DiscriminationRule {
    setNr: number;
    setName: number;
    components: number[];
    foodItems: number[];
    dependencies: DiscriminationSetDependency[];
    maxima: DiscriminationSetMaximum;
}

export interface DiscriminationSetDependency {
    sourceMinimumPortion: number;
    sourceMaximumPortion: number;
    targetDependencySetNr: number;
    targetMaximumPortion: number;
}

export interface DiscriminationSetMaximum {
    maximumTypes: number;
    maximumPortions: number;
}

export interface DiscriminationSetExclusion {
    exclusionEntryNr: number;
    discriminationSets: number[];
    foodItems: number[];
}

export interface DiscriminationFoodItemSetExclusion {
    id: number;
    exceptionChosenFoodItems: number[];
    nutritionId: number;
    exceptionForbiddenFoodItems: number[];
}
 