import * as React from "react";
import ListItem from "@mui/material/ListItem";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Typography from "@mui/material/Typography";
import styles from "./CustomMenu.module.css";
import Fab from '@mui/material/Fab';
import {IconButton} from "@mui/material";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import {OrderState} from "../../../modules/order/OrderTypes";
import {MenuState} from "../../../modules/menu/MenuTypes";
import {discriminateFoodItem} from "../../../modules/order/discriminition/discriminationSelectors";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import AllergenButton from "../AllergenButton";

export interface FoodPortionProps {
    id: number;
    portion: number;
    addPortionHandler: any;
    subtractPortionHandler: any;
    hideForApp: boolean
}

const FoodPortionComponent: React.FC<FoodPortionProps> = (props) => {
    const state = useSelector((state: ApplicationState) => state)
    const myMenuState = state.menu as MenuState;
    const myOrderState = state.order as OrderState;
    const mealMoment = myOrderState.mealMoment;

    const item = myMenuState.menu.foodItems[props.id];
    const orderItem = myOrderState.itemPortions[props.id];
    const portion = !item ? props.portion : orderItem ? orderItem.portion : 0
    let lockResult = discriminateFoodItem(props.id, item.courseGroupId, state);
    const courseGroup = myMenuState.menu.courseGroups[item.courseGroupId];

    const possiblePortions = item.possiblePortions
    const description = item.description
    const hideForApp = !item ? props.hideForApp : item.hideForApp
    const isLocked = lockResult.isLocked
    const isLockedSet = lockResult.isLockedSet
    const allergens = item.allergens
    const allergensList = item.allergensList
    const image_url = item.image_url
    const detailedDescription = item.detailedDescription
    const courseGroupIsBlocked = courseGroup.blocked

    const mealMomentIsBBF = mealMoment.isBBF
    const mealMomentIsBBFSelectable = mealMoment.isBBFSelectable;
    const nutrients = item.nutrients
    const nutrientList = item.nutrientsList

    //Don't show fooditem.
    if (!description || hideForApp) return <></>

    const isFirstPortion = portion === 0;
    const isLastPortion =
        portion === possiblePortions[possiblePortions.length - 1];


    /*don't show button but take up the space (visibility: hidden)*/
    const renderNoAllergenenButton = () => {
        return <IconButton aria-label="no info" className={styles.infoIcon} sx={{ visibility: "hidden" }}>
            <HighlightOffOutlinedIcon></HighlightOffOutlinedIcon>
        </IconButton>
    }

            const renderPortionButtons = () => {
        return <span className={styles.actionsContainer}>
                <Fab variant="circular" color="secondary" aria-label="Remove" className={styles.miniIcon}
                     onClick={() => props.subtractPortionHandler(props.id, portion)}
                     disabled={isFirstPortion || courseGroupIsBlocked}>
                    <RemoveIcon/>
                </Fab>
                <Typography id={styles.counter} variant="body1">
                    {portion ? portion.toLocaleString() : ''}
                </Typography>
                <Fab variant="circular" color="secondary" aria-label="Add" className={styles.miniIcon}
                     onClick={() => props.addPortionHandler(props.id, portion)}
                     disabled={isLocked || isLastPortion || courseGroupIsBlocked}>
                    <AddIcon/>
                </Fab>
            </span>
    }

    return <ListItem disableGutters divider className={styles.listItem}>
        <Typography color={isLockedSet ? "error" : "initial"} variant="subtitle1" className={styles.itemDescription}>
            {description}
        </Typography>
        {(allergens && allergensList.length > 0) || (nutrients && nutrientList.length > 0) || detailedDescription !== null ? <AllergenButton  allergensList={allergensList} description={description} detailedDescription={detailedDescription} image_url={image_url} nutrientList={nutrientList}/>  : renderNoAllergenenButton()}
        {mealMomentIsBBF && !mealMomentIsBBFSelectable ? null : renderPortionButtons()}

    </ListItem>


}

export default FoodPortionComponent;
