import * as React from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import styles from "./CustomMenu.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import {MenuState} from "../../../modules/menu/MenuTypes";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {discriminateFoodCourseGroup} from "../../../modules/order/discriminition/discriminationSelectors";
import {addPortion, subtractPortion} from "../../../modules/order/OrderActions";
import FoodPortionComponent from "./FoodPortionComponent";

export interface FoodCourseGroupProps {
    courseID: number;
    courseGroupID: number;
}

const FoodCourseGroupComponent: React.FC<FoodCourseGroupProps> = (props) => {
    const dispatch = useDispatch()
    const state = useSelector((state: ApplicationState) => state)
    const myMenuState = state.menu as MenuState
    const courseGroup = myMenuState.menu.courseGroups[props.courseGroupID]
    const course = myMenuState.menu.courses[props.courseID];
    const courseGroupTitle = courseGroup?.title
    const title = (course && course.courseGroups.length === 1 && course.title.toUpperCase() === courseGroupTitle.toUpperCase()) ? undefined :  courseGroupTitle
    const IDs = courseGroup.foodItems
    const captionResult = discriminateFoodCourseGroup(props.courseGroupID, state);

   const isExceeded = captionResult.isExceeded
   const caption = courseGroup.caption
   const helpText = captionResult.helpText
   const foodItems = IDs

    const addFoodPortion = (id: number, portion: number) => {
        addPortion(id, portion)(dispatch, myMenuState)
    }
    const subtractPortionHandler = (id: number, portion: number) => {
        subtractPortion(id, portion)(dispatch, myMenuState)
    }

    if (!foodItems) return <CircularProgress/>;

    return <>
        {title ? <Typography className={styles.summary} color={isExceeded ? "error" : "initial"}
                             variant="h6">{title}</Typography> : <span/>
        }
        <Typography className={styles.summary} variant="caption">{helpText ? helpText : caption}</Typography>

        <List>
            {
                foodItems.map((id, i) =>
                   <FoodPortionComponent id={id} key={i} portion={0} addPortionHandler={addFoodPortion} subtractPortionHandler={subtractPortionHandler} hideForApp={false} />
        )
            }
        </List>
    </>;
}

export default FoodCourseGroupComponent