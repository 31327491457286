import * as React from "react";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import styles from "./ErrorComponent.module.css";
import { useTranslation } from 'react-i18next';

export const ErrorComponent = () => {
    const { t } = useTranslation();
    return (
        <Box className={styles.errorPage}>
            <Paper>
                <Box component={'img'} src={process.env.PUBLIC_URL + '404_not_found.jpg'} alt="404 not found" />
            </Paper>

            <Typography variant="body1">
                {t("error.Error")};
            </Typography>
        </Box>
    )
}