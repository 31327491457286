import { AuthState, initialState, AuthenticationResult } from "./authTypes";
import { actionsEnum } from "./authActions";
import { getSessionCookieValue } from './ClientSessionProvider';

export const authReducer = (state: AuthState = initialState, action: any): AuthState => {
    switch (action.type) {
        case actionsEnum.LOGON_USER:
            return handleLogonUser();
        case actionsEnum.LOGON_USER_STARTED:
            return handleLogonUserStarted();
        case actionsEnum.RECEIVE_USER:
            return handleReceiveUser(state, action.payload);
        case actionsEnum.LOGOUT_STARTED:
            return handleLogoutStarted(state);
        case actionsEnum.LOGOUT:
            return handleLogout();
        case actionsEnum.LOGON_FAILED:
            return handleLogonFailed(state, action.payload);
        case actionsEnum.LOGON_EXPIRED:
            return handleLogonExpired(state);
        default:
            return state;
    }
}
 
const handleLogonUser = () => {
    return {
        ...initialState
    }
}

const handleReceiveUser = (state: AuthState, payload: AuthenticationResult) => {
    const sessionCookie = getSessionCookieValue();
    return {
        ...initialState,
        isAuthenticated: payload.isSuccess,
        sid: sessionCookie,
        pid: payload.pid,
        isLocalLogon: payload.isLocalLogon
    }
}

const handleLogonUserStarted = () => {
    const sessionCookie = getSessionCookieValue();
    return {
        ...initialState,
        isAuthenticating: true,
        isExpired: false,
        sid : sessionCookie
    }
}

const handleLogoutStarted = (state: AuthState) => {
    return {
        ...state, 
        isLoggingOut: true,
    }
}
 
const handleLogout = () => {
    return {
        ...initialState   
    }
}

const handleLogonFailed = (state: AuthState, payload: any) => {
    return {
        ...initialState,
        isAuthenticating: false,
        logonFailedMessage : payload
    }
}

const handleLogonExpired = (state: AuthState): AuthState => {
    if (state.isLoggingOut) {
        //do nothing for expiry
        return state;
    }
    return {
        ...initialState,
        isExpired:true
    }
}