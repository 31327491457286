import * as React from "react";
import styles from "./seperatorComponent.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export interface SeperatorComponentProps {
    text: string;
}

const SeperatorComponent: React.FC<SeperatorComponentProps> = ({text}) => {

    return (<Box className={styles.separator}>
        <Typography variant="overline" color="primary">
            {text}
        </Typography>
    </Box>)
}

export default SeperatorComponent