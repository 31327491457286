import { normalize, schema } from "normalizr";
import { Menu } from './MenuTypes';
import { FoodCourseGroup, FoodItem } from "../order/OrderTypes";

const foodItem = new schema.Entity("foodItems", {
});

    
const courseGroup = new schema.Entity("courseGroups", {
    foodItems: [foodItem]
}, {
        processStrategy: (entity: any) => {
            const sorted = entity.foodItems.sort((c1: FoodItem, c2: FoodItem) => c1.menuChartLineNr - c2.menuChartLineNr);
            entity.foodItems = sorted;
            return entity;
        }
    });

const courses = new schema.Entity("courses", {
    courseGroups: [courseGroup],
}, {
        processStrategy: (entity: any) => {
            const sorted = entity.courseGroups.sort((c1: FoodCourseGroup, c2: FoodCourseGroup) => c1.sortOrder - c2.sortOrder);
            entity.courseGroups = sorted;
            return entity;
        }
    });

const mySchema = {
    courses: [courses],
    foodItems : [foodItem]
}

export const formatData = (originalData: any): Menu => {

    const normalized = normalize(originalData, mySchema);
     
    let courses = normalized.entities.courses;
    let sortedCourseKeys = Object.keys(normalized.entities.courses).map(Number);
    sortedCourseKeys = sortedCourseKeys.sort((key1, key2) => courses[key1].sortOrder - courses[key2].sortOrder);

    const menu: Menu = {

        dailyMenu: originalData.dailyMenu,

        courses: normalized.entities.courses,
        courseKeys: sortedCourseKeys,

        courseGroups: normalized.entities.courseGroups,
        courseGroupKeys: Object.keys(normalized.entities.courseGroups).map(Number),

        foodItems: normalized.entities.foodItems,
        foodItemKeys: Object.keys(normalized.entities.foodItems).map(Number),

        menuKey: originalData.menuKey
    }
    
    return menu;
}
