import { Property } from 'csstype';

export const initialProfileState: ProfileState = {
    profiles: [],
    patient: null,
    guests: [],
    diets: null,
    languages: null,
    activeProfileID: null,
    isDisclaimerFirstTimeDisplayed: true,
    snackbar: {
        show: false,
        text: "",
        variant: "normal",
        duration: 10000,
        textAlign: "center"
    },
}

export interface Diet {
    id: number,
    name: string,
}


export interface DietNames {
    culture: string,
    name: string
}

export interface Disclaimers {
    culture: string,
    disclaimer: string
}

export interface Language {
    id: number,
    name: string,
    culture: string
}

export interface Profile {
    id: string,
    isPrimary: boolean,
    name: string,
    avatar: string;
    avatarNumber: string,
    actualDietNames: DietNames[],
    disclaimers: Disclaimers[],
    admissionNR: string,
    disclaimerAccepted: boolean,
    newPatientAdaptDiet: boolean,
    organizationId: number,
    organizationName: string,
    subDepartmentId: number,
    residentPatientId: string,
    preferredDiet: number,
    preferredLanguage: number,
    preferredLanguageCulture: string,
    profilePicture: string,
    profilePictureUrl: string,
    isUsingApp: boolean,
    isUsingAppTxt: string,
}

export interface ProfileState {
    profiles: Profile[];
    patient: Profile;
    guests: Profile[];
    diets: Diet[];
    languages: Language[];
    activeProfileID: string;
    isDisclaimerFirstTimeDisplayed: boolean;
    snackbar: SnackbarStatus;
}

export interface SnackbarStatus {
    show: boolean,
    text: string,
    variant: string,
    duration?: number,
    textAlign: Property.TextAlign
}