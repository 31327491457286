import * as React from "react";
import styles from "./CustomMenu.module.css";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import FoodCourseComponent from "./FoodCourseComponent";
import OverviewMenuComponent from "../overviewMenu/OverviewMenuComponent";
import Box from "@mui/material/Box";

const CustomMenuComponent: React.FC = () => {
    const {t} = useTranslation();
    const myMenuState = useSelector((state: ApplicationState) => state.menu)
    // const orderState = useSelector((state: ApplicationState) => state.order)
    const courseIDs = myMenuState.menu.courseKeys
    const loading = myMenuState.loading
    // const isBBF = orderState.mealMoment.isBBF
    // const isBBFSelectable = orderState.mealMoment.isBBFSelectable

    return <>
        {loading ? <CircularProgress/> : courseIDs.length > 0 ? (<Box className={styles.container}>

            {
                courseIDs.map((id, i) =>
                    <FoodCourseComponent expanded={false} isExceeded={false} id={id} key={i}/>
                )
            }
        </Box>) : (<Box>
            <Paper elevation={1} square={true}>
                <List id={styles.list}>
                    <Typography variant="body1">{t("order.Cmenu")}</Typography>
                </List>
            </Paper>
        </Box>)}        
        <OverviewMenuComponent />        
    </>

}

export default CustomMenuComponent

