import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from '@mui/material/IconButton';

import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import SuccessIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./ErrorComponent.module.css";
import {SnackbarStatus} from "../../modules/order/OrderTypes";
import {green} from '@mui/material/colors';
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";


export interface ErrorProps {
    snackbar: SnackbarStatus,
}

export interface ErrorSnackbarComponentDispatchProps {
    closeSnackbar: () => void;
}


export const ErrorSnackbarComponent: React.FC<ErrorProps & ErrorSnackbarComponentDispatchProps> = ({
                                                                                                       snackbar,
                                                                                                       closeSnackbar
                                                                                                   }) => {
    const {t} = useTranslation();

    const errorIcon = () => {
        if (snackbar.variant === "error") {
            return <ErrorIcon className={styles.errorIcon}/>
        }
    }

    const warningIcon = () => {
        if (snackbar.variant === "warning") {
            return <WarningIcon className={styles.warningIcon}/>
        }
    }

    const successIcon = () => {
        if (snackbar.variant === "success") {
            return <SuccessIcon className={styles.succesIcon}/>
        }
    }

    const snackBarMessages: string[] = snackbar.text.split("\n");

    return <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal:"center" }}
        open={snackbar.show}
        onClose={closeSnackbar}
        autoHideDuration={snackbar.duration}
        resumeHideDuration={snackbar.duration ? snackbar.duration * 2 : 0}>

        <SnackbarContent
            sx={{
                backgroundColor: (theme) => snackbar.variant === "error" ? theme.palette.error.dark : snackbar.variant === "warning" ? theme.palette.error.light : snackbar.variant === "success" ? green[400] : null
            }}
            aria-describedby="client-snackbar"
            message={<Box className={styles.snackbar}>
                {
                    snackbar.variant === "success" ? successIcon() : snackbar.variant === "warning" ? warningIcon() : errorIcon()
                }
                <Box> {
                    snackBarMessages.map((message, i) => {
                        return <Box key={i}>{message.indexOf("|tfrsc") < 0 ? message : t("resource." + message)}</Box>
                        //return <div key={i}>{message}</div>
                    })}
                </Box>
            </Box>}
            action={
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={closeSnackbar}
                >
                    <CloseIcon/>
                </IconButton>
            }/>
    </Snackbar>
}