import styles from "./dailyMenu/DailyMenu.module.css";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {IconButton} from "@mui/material";
import * as React from "react";
import {Allergen, Nutrient} from "../../modules/order/OrderTypes";
import {showFoodInformationDialog} from "../../modules/order/OrderActions";
import {useDispatch} from "react-redux";

interface AllergenButtonProps {
    description: string
    allergensList: Allergen[]
    nutrientList: Nutrient[]
    image_url: string
    detailedDescription: string
}
const AllergenButton: React.FC<AllergenButtonProps> = ({description, allergensList, nutrientList, image_url, detailedDescription}) => {
    const dispatch = useDispatch()

    const  displayFoodInformationDialog= (description: string, allergensList: Allergen[], nutrientList: Nutrient[], url_image: string, detailedDescription: string) => {
        showFoodInformationDialog(description, allergensList, nutrientList, url_image, detailedDescription)(dispatch)
    }

    return (<IconButton aria-label="info" className={styles.infoIcon}
                       onClick={() => displayFoodInformationDialog(description, allergensList, nutrientList, image_url, detailedDescription)}>
        <InfoIcon>
        </InfoIcon>
    </IconButton>)
}

export default AllergenButton