import axios from "axios";

export const configAction = {
    RECEIVE_CONFIG: "RECEIVE_CONFIG",
}

export const requestConfig = () => {
    return (dispatch: any) => {
        return axios({
            url: "/api/hospital/config",
            method: "get",
        })
        .then((response: any) => {
            console.log(response);
            dispatch(receiveConfig(response.data));
        });
    };
}

export const receiveConfig = (data: any) => {
    return {
        type: configAction.RECEIVE_CONFIG,
        payload: data
    };
}