import * as React from "react";
import TodayIcon from "@mui/icons-material/Today"
import Typography from "@mui/material/Typography";
import styles from "./OrderMenu.module.css";
import DateTimeLabel, {DateTimeLabelFormat} from "../../dateTimeLabel";
import Box from "@mui/material/Box";

export interface InformationHeaderProps {
    title: string,
    date: Date,
}

const InformationHeaderComponent: React.FC<InformationHeaderProps> = ({title, date}) => {
    return <Box sx={{backgroundColor: theme => theme.informationHeader.main}} className={styles.header}>
        <TodayIcon id={styles.headerContent}/>
        <span>
                    <Typography display="inline" variant="subtitle2" id={styles.headerContent}>
                    {title} /
                       </Typography>
                    <Typography display="inline" variant="body2" id={styles.headerContent}>
                        <DateTimeLabel value={date} type={DateTimeLabelFormat.FullDate}/>
                    </Typography>
                </span>

    </Box>


}

export default InformationHeaderComponent
