import * as moment from 'moment';
import { Profile } from '../profile/ProfileTypes';


export const initialState: AuthState = {
    isAuthenticating: false,
    isAuthenticated: false,
    isExpired: false,
    isLoggingOut: false,
    logonFailedMessage: "",
    isLocalLogon: false,
    sid: "",
    pid: ""
}

export interface AuthState {
    isAuthenticating: boolean,
    isAuthenticated: boolean;
    isExpired: boolean;
    isLoggingOut: boolean;
    isLocalLogon: boolean;
    logonFailedMessage: string;
    sid: string;
    pid: string;
}

export interface AuthenticationResult {
    isSuccess: boolean;
    errorMessage: string;
    pid: string; 
    profiles: Profile[]; 
    isLocalLogon: boolean;
}
 
export interface PatientUser {
    patientNumber: string;
    //dd:MM/YYYY format
    birthdate: moment.Moment;
    displayName: string;
}

export interface LoginCredentials {
    patientNumber: string;
    //dd:MM/YYYY format
    birthdate: moment.Moment | null;
    preferredCulture: string;
}