import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import i18n from 'i18next';
import * as moment from 'moment';
import * as React from "react";
import {useTranslation} from 'react-i18next';
import {LoginCredentials} from "../../../modules/auth/authTypes";
import styles from "./FormsLoginComponent.module.css";
import {useNavigate} from "react-router-dom";
import HelpComponent from "../../help/HelpComponent";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from '@mui/x-date-pickers';
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from 'react-redux'
import {ApplicationState} from "../../../store";
import {Logon, LogonFailed} from "../../../modules/auth/authActions";
import ExpiredComponent from "../ExpiredComponent";

const defaultCredentials: LoginCredentials = {
    patientNumber: "",
    birthdate: null,
    preferredCulture: i18n.language
}

const FormsLoginComponent: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const dispatch = useDispatch()

    const isLoading = useSelector((state: ApplicationState) => state.auth.isAuthenticating)
    const isAuthenticated= useSelector((state: ApplicationState) => state.auth.isAuthenticated)
    const isExpired = useSelector((state: ApplicationState) => state.auth.isExpired)
    const logonFailedMessage = useSelector((state: ApplicationState) => state.auth.logonFailedMessage)

    const [credentials, setCredentials] = React.useState<LoginCredentials>(defaultCredentials)
    const [invalidDate, setInvalidDate] = React.useState<boolean>(false)



    const handleDateChange = (date: moment.Moment) => {
        setCredentials({...credentials, birthdate: date})
    }

    const handleSubmit = async (event: any) => {
        const loginCredentials = {...credentials, preferredCulture: i18n.language}
        setCredentials(loginCredentials)
        event.preventDefault();

        if (loginCredentials.birthdate === null) {
            dispatch(LogonFailed(t("login.FoutGebDat")));
            return;
        }

        if (loginCredentials.patientNumber === "") {
            dispatch(LogonFailed(t("login.FoutPatId")));
            return;
        }

        await Logon(loginCredentials, dispatch)
    }

    const onPatientNumberChanged = (event: any) => {
        setCredentials({...credentials, patientNumber: event.target.value})
    }

    React.useEffect(() => {
        if (isAuthenticated) {
            navigate('/')
        }
    }, [isAuthenticated])

    if (isExpired) {
        return <ExpiredComponent/>
    }
    const newBirthdate = () => {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={i18n.language}>
                <DesktopDatePicker
                    // cancelLabel={this.props.t("algemeen.Annuleer")}
                    // okLabel={this.props.t("algemeen.Ok")}
                    label={t("login.GebDat")}
                    value={credentials.birthdate}
                    format="DD/MM/YYYY"
                    onChange={handleDateChange}
                    className={styles.inputField}
                    slotProps={{
                        textField: {
                            helperText: invalidDate ? t("login.OngeldigeGebDat") : "",
                        },
                    }}
                    onError={(error) => {
                        if (error === 'invalidDate') {
                            setInvalidDate(true)
                        } else {
                            setInvalidDate(false)
                        }
                    }
                    }
                />
            </LocalizationProvider>
        )
    }

    const patientNumber = () => {
        return <TextField
            id="patient_number"
            InputProps={{
                endAdornment: (
                    <HelpComponent code="INFOPATNR"/>
                ),
            }}
            label={t("login.PatId")}
            margin="normal"
            inputMode="numeric"
            disabled={isLoading}
            value={credentials.patientNumber}
            onChange={onPatientNumberChanged}
            className={styles.inputField}
        />
    }

    const loginButton = () => {

        return <Button type="submit"
                       className={styles.loginButton} disabled={isLoading} variant="contained" size="large"
                       color="primary" sx={{m:'12px'}}>
            {t("algemeen.Aanmelden")}
        </Button>
    }

    let logonFailed;

    if (logonFailedMessage) {
        logonFailed = <Typography variant="caption" color="error">
            {logonFailedMessage}
        </Typography>
    }

    return <form
        onSubmit={handleSubmit}>
        <Box>
            <Box mb={1.5}>{t("login.Welkom3")}</Box>
            {newBirthdate()}
            {patientNumber()}
        </Box>
        <Box>
            {loginButton()}
        </Box>
        <Box>
            {logonFailed}
        </Box>
    </form>;
}

export default FormsLoginComponent



