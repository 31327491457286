import AppBar from '@mui/material/AppBar';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import DateTimeLabel, {DateTimeLabelFormat} from "../../../dateTimeLabel";
import styles from "./OverviewMenu.module.css";
import {useTranslation} from "react-i18next";
import FoodItemExtraComponent from "../dailyMenu/FoodItemExtraComponent";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {confirmMenu, setOverviewPanel} from "../../../modules/order/OrderActions";
import FoodItemComponent from "../FoodItemComponent";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Prompt from "../../../hooks/Prompt";

const OverviewMenuComponent: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const dispatch = useDispatch()

    const state = useSelector((state: ApplicationState) => state)
    const myOrderState = state.order
    const itemPortions = myOrderState.itemPortions;
    const extraItems = myOrderState.foodItemsExtra;

    let sortedItemPortions = Object.keys(itemPortions).map(k => parseInt(k));
    sortedItemPortions = sortedItemPortions.sort((key1, key2) => itemPortions[key1].menuChartLineNr - itemPortions[key2].menuChartLineNr);

    const myFoodItems: number[] = Array.from(sortedItemPortions.values());

    const myExtraFoodItems: number[] = extraItems ? Object.keys(extraItems).map(key => {
        const index = key as unknown as number
        return extraItems[index].id
    }) : []

    const foodItems: number[]= myFoodItems.length > 1 && myFoodItems[0] === 0 ?  myFoodItems.slice(1) : myFoodItems

    const portions= myOrderState.itemPortions
    const foodItemsExtra= myExtraFoodItems
    const mealMoment= myOrderState.mealMoment
    const overviewPanelOpen= myOrderState.overviewPanelOpen

    const handleClickOpen = () => {
        dispatch(setOverviewPanel(true))
    };

    const handleClose = () => {
        dispatch(setOverviewPanel(false))
    };

    const handleConfirmMenu = () => {
        confirmMenu(false, false)(dispatch, state).then((e) => {
            if (e) navigate('/menu');
        } )
    }

    const ButtonComponent = () => (
        <Button className={styles.largeButton} variant="contained" color="primary"
                onClick={handleClickOpen} disabled={mealMoment.isBBF && !mealMoment.isBBFSelectable}>
            {t('overViewMenu.Bevestig')}
        </Button>
    )

    const ToolBarComponent = () => (
        <Toolbar className={styles.toolBar}>
            <Typography variant="h6">
                {t('overViewMenu.Bevestig')}
            </Typography>
            <IconButton onClick={handleClose} aria-label="Close">
                <CloseIcon/>
            </IconButton>
        </Toolbar>
    )

    const AdaptButton = () => (
        <Button className={styles.confirmButton} variant="contained" color="secondary"
                onClick={handleClose}>
            {t('overViewMenu.AanpassenM')}
        </Button>
    )

    const ActionComponent = () => (
        <Box className={styles.buttonContainer}>
            <Prompt when={false} message={null} />
            <Button className={styles.confirmButton} variant="contained" color="primary"
                    onClick={handleConfirmMenu}>
                {t('overViewMenu.BestelM')}
            </Button>
            <AdaptButton/>
        </Box>
    )

    const FoodListDialog = () => (<>        
            <ButtonComponent/>
            <Dialog className={styles.dialog}
                    fullScreen
                    open={overviewPanelOpen}
                    onClose={handleClose}>
                <AppBar
                    sx={{
                        backgroundColor: theme => theme.overviewHeader.main
                    }}
                >
                    <ToolBarComponent/>
                </AppBar>
                <Box className={styles.dialogContainer}>
                    <Typography variant="subtitle1">
                        {mealMoment.title} voor <DateTimeLabel value={mealMoment.date}
                                                               type={DateTimeLabelFormat.FullDate}/>
                    </Typography>
                    {foodItems.length > 0 && <br/>}
                    <List>
                        {
                            foodItems.map((id: number, index: number) => {
                                let showDivider = foodItems.length !== index + 1;                                
                                let portion = portions[id];
                                let amount = 0;
                                if (portion) {
                                    amount = portion.portion;
                                }
                                return <FoodItemComponent id={id} key={index} showDivider={showDivider}
                                    amount={amount} mealMomentIsBlocked={false} showNutrientIcon={false} />
                            })
                        }
                    </List>

                    {foodItemsExtra.length > 0 && <br/>}
                    {foodItemsExtra.length > 0 &&
                        <Typography variant="subtitle1">
                            {t('overViewMenu.Extra')} {mealMoment.title}
                        </Typography>
                    }
                    {foodItemsExtra.length > 0 &&
                        <List>
                            {
                                foodItemsExtra.map((food: number, i: number) =>
                                    <FoodItemExtraComponent id={food} key={i} divider={true}/>
                                )
                            }
                        </List>
                    }

                    <ActionComponent/>
                </Box>
            </Dialog>
        </>

    )

    const EmptyListDialog = () => (
        <>
            <ButtonComponent/>
            <Dialog className={styles.dialog}
                    fullScreen
                    open={overviewPanelOpen}
                    onClose={handleClose}>
                <AppBar className={styles.appBar}>
                    <ToolBarComponent/>
                </AppBar>
                <Box className={styles.dialogContainer}>
                    <Typography variant="subtitle1">
                        {mealMoment.title} voor <DateTimeLabel value={mealMoment.date}
                                                               type={DateTimeLabelFormat.FullDate}/>
                    </Typography>
                    <List id={styles.list}>
                        {
                            <Typography variant="subtitle1"
                                        color="error">{t('overViewMenu.GeenM')}</Typography>
                        }
                    </List>
                    <Box className={styles.buttonContainer}>
                        <AdaptButton/>
                    </Box>

                </Box>
            </Dialog>
        </>
    )

    return <>{foodItems[0] !== 0 ?
        (<FoodListDialog />) : (<EmptyListDialog />)}        
    </>
}

export default OverviewMenuComponent;
