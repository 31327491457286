import * as React from "react";
import {useTranslation} from "react-i18next";

export enum DateTimeLabelFormat {
    Date,
    Time,
    DateTime,
    FullDate,
    Day
}

export interface DateTimeLabelProps {
    value: any;
    type: DateTimeLabelFormat;
}

const DateTimeLabel: React.FC<DateTimeLabelProps> = (props) => {
    const {t} = useTranslation()

    const format_two_digits = (n: any) => {
        return n < 10 ? "0" + n : n;
    }

    const time_format = (d: any) => {
        var hours = format_two_digits(d.getHours());
        var minutes = format_two_digits(d.getMinutes());
        // var seconds = this.format_two_digits(d.getSeconds());
        return hours + ":" + minutes;
    }


        const weekDay = [
            t('weekDay.Zondag'),
            t('weekDay.Maandag'),
            t('weekDay.Dinsdag'),
            t('weekDay.Woensdag'),
            t('weekDay.Donderdag'),
            t('weekDay.Vrijdag'),
            t('weekDay.Zaterdag'),
        ];

        const month = [
            t('month.januari'), t('month.februari'), t('month.maart'), t('month.april'), t('month.mei'), t('month.juni'), t('month.juli'), t('month.augustus'), t('month.september'), t('month.oktober'), t('month.november'), t('month.december'),

        ];

        let value = props.value;

        if (value === undefined) {
            return <span/>;
        }

        if (typeof (value) == "string") {
            value = new Date(value);
        }

        let valueFormat;

        switch (props.type) {
            case DateTimeLabelFormat.Date:
                valueFormat = value.getDate() + "-" + (value.getMonth() + 1) + "-" + value.getFullYear();
                break;

            case DateTimeLabelFormat.Time:
                valueFormat = time_format(new Date(value.getTime()));
                break;
            case DateTimeLabelFormat.FullDate:
                valueFormat = weekDay[value.getDay()] + " " + value.getDate() + " " + month[(value.getMonth())] + " " + value.getFullYear();
                break;
            case DateTimeLabelFormat.Day:
                valueFormat = weekDay[value.getDay()];
                break;
            default:
                valueFormat = value.getDate() +
                    "/" +
                    (value.getMonth() + 1) +
                    "/" +
                    value.getFullYear() +
                    " " +
                    time_format(value);
                break;
        }

        return <span>
                   <span>{valueFormat}</span>
               </span>;
}

export default DateTimeLabel
