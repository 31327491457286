import * as React from 'react'; 
import { Typography } from '@mui/material';
import styles from "./Profile.module.css";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import ProfileComponent from "./ProfileComponent";
import DisclaimerComponent from "./DisclaimerComponent";

const ProfilePageComponent: React.FC = () => {
    const profile = useSelector((state: ApplicationState) => state.profile)
    const patient = profile.patient;
    const isDisclaimerTop = profile.isDisclaimerFirstTimeDisplayed
    const organizationName = patient.organizationName


        return <>
            <Typography noWrap variant="h6" className={styles.welcome} sx={{ m: '20px' }}>
                {organizationName}
            </Typography>

            {isDisclaimerTop && <DisclaimerComponent />}
            <ProfileComponent />
            {!isDisclaimerTop && <DisclaimerComponent />}
        </>

}

export default ProfilePageComponent