import axios, { AxiosResponse } from "axios";
import { Profile } from './ProfileTypes';
import { Property } from 'csstype';
import i18n from 'i18next';
import { getMealDays } from "../menu/MenuActions";

export const profileAction = {
    SET_ACTIVE_PROFILE: "SET_ACTIVE_PROFILE",
    RECEIVE_DIETS: "RECEIVE_DIETS", 
    RECEIVE_LANGUAGES: "RECEIVE_LANGUAGES",
    RECEIVE_PROFILE: "RECEIVE_PROFILE",
    ACCEPT_DISCLAIMER: "ACCEPT_DISCLAIMER",
    SHOW_SNACKBAR: "SHOW_SNACKBAR",
    CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
}

export const setActiveProfile = (profile: Profile) => {
    return {
        type: profileAction.SET_ACTIVE_PROFILE,
        payload: profile,
    };
}

export const getDiets = (profile: Profile) => {
    return (dispatch: any) => {
        return axios({
            url: "/api/patient/diet",
            method: "get",
            headers: { 'patientId': profile.id }
        }).then(response => {
            dispatch(receiveDiets(response.data.indexOf("|tfrsc") < 0 ? response.data : i18n.t("resource." + response.data))); // response.data));
        });
    };
}

export const getLanguages = () => {
    return (dispatch: any) => {
        return axios({
            url: "/api/patient/language",
            method: "get"
        }).then(response => {
            dispatch(receiveLanguages(response.data.indexOf("|tfrsc") < 0 ? response.data : i18n.t("resource." + response.data))); //response.data));
        });
    };
}

export const receiveDiets = (data: any) => {
    return {
        type: profileAction.RECEIVE_DIETS,
        payload: data,
    };
}

export const receiveLanguages = (data: any) => {
    return {
        type: profileAction.RECEIVE_LANGUAGES,
        payload: data,
    };
}

interface SaveProfileResponse {
    isSuccess: boolean;
    errorMessage: string;
}

export const approveDisclaimer = () => {
    return (dispatch: any) => {
        console.log('approveDisclaimer.action dispatcher exec.');
        return axios({
            url: "/api/patient/disclaimer",
            method: "post"
        })
            .then((response: any) => {
                dispatch({
                    type: profileAction.RECEIVE_PROFILE,
                    payload: response.data,
                });
            });
    };
}

//https://cegekacmealtest.azurewebsites.net
export const saveProfile = (patientNumber: string, isDisclaimerChecked: boolean, preferredDietNumber: number, preferredLanguageNumber: number, isUsingApp: boolean, newCulture: string, profile: Profile) => {
    return (dispatch: any) => {
        return axios({
            url: "/api/patient/profile",
            method: "post",
            data: {
                patientNumber,
                isDisclaimerChecked,
                preferredDietNumber,
                preferredLanguageNumber,
                isUsingApp,
                newCulture
            },
        })
        .then((response: AxiosResponse<SaveProfileResponse>) => {
            let result = response.data;

            if (result && result.isSuccess === undefined) {
                dispatch({
                    type: profileAction.RECEIVE_PROFILE,
                    payload: result,
                });
                dispatch(showSnackbar(i18n.t('profiel.ProfielOk'), "success", 10000));

                getMealDays(profile, dispatch);
            }
            else {
                if (result && !result.isSuccess) {
                    dispatch(showSnackbar(i18n.t('profiel.' + result.errorMessage), "error", null, "left"));
                }
                else {
                    dispatch(showSnackbar(i18n.t('profiel.ProfielOk'), "success", 10000));

                    getMealDays(profile, dispatch);
                }                    
            }           
        });



    };
}

export const showSnackbar = (text: string, variant: string, duration: number, textAlign: Property.TextAlign = "center") => {
    return {
        type: profileAction.SHOW_SNACKBAR,
        payload: {
            text: text,
            variant: variant,
            duration: duration,
            textAlign: textAlign
        },
    };
}

export const closeSnackbar = () => {
    return {
        type: profileAction.CLOSE_SNACKBAR,
    };
}
