import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";
import styles from "./LoginComponent.module.css";
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import SeperatorComponent from "../seperator/SeperatorComponent";
import FormsLoginComponent from "./forms/FormsLoginComponent";
import CsamLoginComponent from "./csam/CsamLoginComponent";
import ExpiredComponent from "./ExpiredComponent";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import Box from "@mui/material/Box";


const LoginComponent: React.FC = () => {
    const {t} = useTranslation();
    const isExpired = useSelector((state: ApplicationState) => state.auth.isExpired)
    const isLoading = useSelector((state: ApplicationState) => state.auth.isAuthenticating)
    const isAuthenticated = useSelector((state: ApplicationState) => state.auth.isAuthenticated)
    const authenticationConfiguration = useSelector((state: ApplicationState) => state.config.authentication)
    const navigate = useNavigate();

    React.useEffect(() => {
        if (isAuthenticated) {
            navigate('/')
        }
    }, [isAuthenticated])

    if (isExpired) {
        return <ExpiredComponent/>
    }

    const title = isLoading === false ?
        <span>{t("login.Welkom1")} <br/> {t("login.Welkom2")}</span> :
        <span>{t("login.Bezig")}</span>

    const showSeperatorComponentAfterForms = authenticationConfiguration.isFormsAuthenticationEnabled && authenticationConfiguration.isCsamAuthenticationEnabled;

    return isLoading ? (
        <Box>
            <Typography variant="h5" gutterBottom className={styles.welcomeMessage}>
                {title}
            </Typography>
            <CircularProgress/>
        </Box>
    ) : (
        <Box>
            <Typography variant="h5" gutterBottom className={styles.welcomeMessage}>
                {title}
            </Typography>
            {authenticationConfiguration.isFormsAuthenticationEnabled &&
                <div className={styles.loginContainer}><FormsLoginComponent/></div>}
            {showSeperatorComponentAfterForms && <SeperatorComponent text={t('login.OF')}/>}
            {authenticationConfiguration.isCsamAuthenticationEnabled &&
                <div className={styles.loginContainer}><CsamLoginComponent/></div>}
            <hr/>
        </Box>
    )
}

export default LoginComponent

