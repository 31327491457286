import * as React from "react";
import {useNavigate} from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material//ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import {useTranslation} from 'react-i18next';
import axios from "axios"
import {Language} from "../../modules/profile/ProfileTypes";
import ConfirmationDialog from "./ConfirmationDialog";
import AboutDialog from "./AboutDialog";
import { Logoff } from "../../modules/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";


interface MenuProps extends MenuListProps {
    openMenu: boolean
}

interface MenuListProps {
    profiles: any
    cmealLanguages?: Language[]
    getLanguages: () => void
    isProfileEnabled?: boolean
    setOpenMenu: (open: boolean) => void
}

const MenuList: React.FC<MenuListProps> = ({ profiles, cmealLanguages, getLanguages, isProfileEnabled, setOpenMenu }) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const authState = useSelector((state: ApplicationState) => state.auth)

    const [openLogout, setOpenLogout] = React.useState(false)
    const [openAbout, setOpenAbout] = React.useState(false)
    const [clientVersion, setClientVersion] = React.useState('')
    const [backendVersion, setBackendVersion] = React.useState('')

    if (profiles.length > 0 && cmealLanguages == null) {
        getLanguages();
    }

    const goTo = (route: string) => {
        setOpenMenu(false)
        navigate(route, {state: {openMenu: false, openLanguages: false}});
    }

    const handleClickOpenAbout = () => {
        axios.get<string>("/api/hospital/helptip?code=ABOUT&culture=" + i18n.language)
            .then(response => {
                const responseText = response.data;

                axios.get<string>("/home/info")
                    .then(infoResponse => {
                        const infoResponseText = infoResponse.data;
                        setBackendVersion(responseText)
                        setClientVersion(infoResponseText)
                        setOpenAbout(true)
                    })
            })
    };

    const handleClickOpenLogout = () => {
        setOpenLogout(true)
    };

    const logout = () => {
        setOpenMenu(false)
        setOpenLogout(false)
        Logoff()(dispatch, authState)

    }

    return (
        <>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => {
                        goTo('/')
                    }}>
                        <ListItemIcon><HomeIcon/></ListItemIcon>
                        <ListItemText primary={t("profiel.Home")}/>
                    </ListItemButton>
                </ListItem>

                {isProfileEnabled &&
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => {
                            goTo('/profile')
                        }}>
                            <ListItemIcon><PersonIcon/></ListItemIcon>
                            <ListItemText primary={t('profiel.Profiel')}/>
                        </ListItemButton>
                    </ListItem>}

                <ListItem disablePadding>
                    <ListItemButton onClick={handleClickOpenAbout}>
                        <ListItemIcon><InfoIcon/></ListItemIcon>
                        <ListItemText primary={t('profiel.About')}/>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={handleClickOpenLogout}>
                        <ListItemIcon><LogoutIcon/></ListItemIcon>
                        <ListItemText primary={t('algemeen.Afmelden')}/>
                    </ListItemButton>
                </ListItem>
            </List>
            <ConfirmationDialog logout={logout} openLogout={openLogout} setOpenLogout={setOpenLogout} setOpenMenu={setOpenMenu} />
            <AboutDialog openAbout={openAbout} backendVersion={backendVersion} clientVersion={clientVersion} setOpenAbout={setOpenAbout} setOpenMenu={setOpenMenu} />
        </>
    )
}
export const Menu: React.FC<MenuProps> = ({
                                              openMenu,
                                              setOpenMenu,
                                              profiles,
                                              cmealLanguages,
                                              getLanguages,
                                              isProfileEnabled
                                          }) => {

    return (
        <Drawer anchor="right" open={openMenu} onClose={() => setOpenMenu(false)}>
            <MenuList profiles={profiles} cmealLanguages={cmealLanguages} getLanguages={getLanguages}
                isProfileEnabled={isProfileEnabled} setOpenMenu={setOpenMenu} />
        </Drawer>
    )
}