import { unstable_useBlocker as useBlocker } from 'react-router-dom'
import Box from "@mui/material/Box";

function Prompt(props: { when: any; message: string }) {
    const block = props.when

    useBlocker(() => {
        if (block) {
            return ! window.confirm(props.message)
        }
        return false
    })

    return (
        <Box key={block}/>
)
}

export default Prompt