import {Button, Dialog, Divider, Typography, Box} from "@mui/material";
import styles from "./OrderMenu.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import * as React from "react";
import { FoodItemDialogStatus } from "../../modules/order/OrderTypes";
import {useTranslation} from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {styled} from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'whitesmoke',
    },
}));
interface FoodInformationDialogComponentProps {
    closeDialog: (close: boolean) => void
    foodItemDialog: FoodItemDialogStatus
    featureMealPictures: boolean
}
const FoodInformationDialogComponent: React.FC<FoodInformationDialogComponentProps> = ({foodItemDialog, closeDialog, featureMealPictures}) => {
    const {t} = useTranslation()

    const [containedAllergens, setContainedAllergens] = React.useState<string[]>([])
    const [containedTracesAllergens, setContainedTracesAllergens] = React.useState<string[]>([])
    const [noInfoOnAllergens, setNoInfoOnAllergens] = React.useState<string[]>([])
    const [wrongInfoOnAllergens, setWrongInfoOnAllergens] = React.useState<string[]>([])


    // const [nutrients100g, setNutrients100g] = React.useState<string[]>([])
    // const [nutrientsPortion, setNutrientsPortion] = React.useState<string[]>([])

    React.useEffect(() => {
        if (foodItemDialog.allergens) {
            const foodContainedAllergens = foodItemDialog.allergens.map(allergen => {
                if (allergen.type === "+") {
                    return allergen.name
                }
                return undefined
            }). filter(e => !!e)
            setContainedAllergens(foodContainedAllergens)

            const foodContainedTracesAllergens = foodItemDialog.allergens.map(allergen => {
                if (allergen.type === "#") {
                    return allergen.name
                }
                return undefined
            }). filter(e => !!e)
            setContainedTracesAllergens(foodContainedTracesAllergens)

            const foodNoInfoOnAllergens = foodItemDialog.allergens.map(allergen => {
                if (allergen.type === "?") {
                    return allergen.name
                }
                return undefined
            }). filter(e => !!e)
            setNoInfoOnAllergens(foodNoInfoOnAllergens)

            const foodWrongInfoOnAllergens = foodItemDialog.allergens.map(allergen => {
                if (allergen.type === "/") {
                    return allergen.name
                }
                return undefined
            }). filter(e => !!e)
            setWrongInfoOnAllergens(foodWrongInfoOnAllergens)
        }
    }, [foodItemDialog.allergens])

    const renderNutrientTable = () => {
        return <Table size="small" sx={{ borderColor: "lightgrey", borderStyle: "solid", borderWidth: "thin", borderCollapse: "unset" }}>
            <TableHead>
                <TableRow>
                    <TableCell align="left" sx={{ pt: 0, pb: 0 }}><Typography sx={{ fontWeight: 600 }} variant="body2" color="textSecondary">{t('allergeen.Omschrijving')}</Typography></TableCell>
                    <TableCell align="right" sx={{ pt: 0, pb: 0 }}><Typography sx={{ fontWeight: 600 }} variant="body2" color="textSecondary">100g</Typography></TableCell>
                    <TableCell align="right" sx={{ pt: 0, pb: 0 }}><Typography sx={{ fontWeight: 600 }} variant="body2" color="textSecondary">{t('algemeen.Portie')}</Typography></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {foodItemDialog.nutrients.map((nutrient) => (
                    <StyledTableRow>
                        <TableCell align="left" sx={{ borderBottom: "none", pt: 0, pb: 0 }}>
                            <Typography variant="body2" color="textSecondary">{nutrient.name}</Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: "none", pt: 0, pb: 0 }}>
                            <Typography variant="body2" color="textSecondary">{nutrient.value.toFixed(2) + " " + nutrient.unit.substr(0, 2) + "."}</Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: "none", pt: 0, pb: 0 }}>
                            <Typography variant="body2" color="textSecondary">{nutrient.totCalculatedValuePerPortion.toFixed(2) + " " + nutrient.unit.substr(0, 2) + "."}</Typography>
                        </TableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    }



    //Classes style is used to override the default 48 px margin of material ui
    return <Dialog classes={{ paper: styles.dialog }} onClose={closeDialog} aria-labelledby="food-info-dialog" open={foodItemDialog.show} >
        <Card sx={{ overflow: "auto", height: "auto" }}>
            {foodItemDialog.url_image !== "" && featureMealPictures ? <Box className={styles.foodInformationImage}>
                <Box component='img' alt='' src={foodItemDialog.url_image}
                     onError={(e: any) => { e.target.onerror = null; e.target.style.display = "none" }} />
            </Box> : null}
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {foodItemDialog.description}
                </Typography>
                {containedAllergens.length + containedTracesAllergens.length + noInfoOnAllergens.length + wrongInfoOnAllergens.length > 0 ?
                    <Typography className={styles.foodDialogTitle} variant="body2" color="textSecondary">
                        <b>{t('allergeen.Titel')} </b>
                    </Typography> : null}

                {containedAllergens && containedAllergens.length > 0 ? <Typography variant="body2" className={styles.nutrientDividerFullWidth} color="textSecondary">
                    <b> {t('allergeen.Bevat')} </b> {containedAllergens.join(', ').toLocaleLowerCase()}
                </Typography> : null}
                {containedTracesAllergens && containedTracesAllergens.length > 0 ? <Typography variant="body2" className={styles.nutrientDividerFullWidth} color="textSecondary">
                    <b> {t('allergeen.BevatSporen')} </b> {containedTracesAllergens.join(', ').toLocaleLowerCase()}
                </Typography> : null}
                {noInfoOnAllergens && noInfoOnAllergens.length > 0 ? <Typography variant="body2" className={styles.nutrientDividerFullWidth} color="textSecondary">
                    <b> {t('allergeen.GeenInfo')} </b> {noInfoOnAllergens.join(', ').toLocaleLowerCase()}
                </Typography> : null}
                {wrongInfoOnAllergens && wrongInfoOnAllergens.length > 0 ? <Typography variant="body2" className={styles.nutrientDividerFullWidth} color="textSecondary">
                    <b> {t('allergeen.GeenJuisteInfo')} </b> {wrongInfoOnAllergens.join(', ').toLocaleLowerCase()}
                </Typography> : null}

                {(containedAllergens.length + containedTracesAllergens.length + noInfoOnAllergens.length + wrongInfoOnAllergens.length > 0) && foodItemDialog.nutrients && foodItemDialog.nutrients.length > 0 ? <Divider sx={{ pb: '2px' }} /> : null}
                {foodItemDialog.nutrients && foodItemDialog.nutrients.length > 0 ? <Typography variant="body2" className={styles.foodDialogTitle} sx={{ pt: '5px' }} color="textSecondary">
                    <b>{t('nutrient.Titel')} </b>
                </Typography> : null}
                {foodItemDialog.nutrients && foodItemDialog.nutrients.length > 0 ? renderNutrientTable() : null}

                {foodItemDialog.detailedDescription && (containedAllergens.length + containedTracesAllergens.length + noInfoOnAllergens.length + wrongInfoOnAllergens.length > 0) && (!foodItemDialog.nutrients || foodItemDialog.nutrients.length <= 0) ? <Divider light sx={{ mt: '2px', mb: '2px' }} /> : null}
                {foodItemDialog.detailedDescription ? <Typography variant="body2" className={styles.foodDialogTitle} sx={{ pt: '5px' }} color="textSecondary">
                    <b> {t('allergeen.Omschrijving')}</b> </Typography> : null}
                {foodItemDialog.detailedDescription ? <Typography variant="body2" className={styles.dividerFullWidth} sx={{paddingLeft: '2px' }} color="textSecondary">{foodItemDialog.detailedDescription.toLocaleLowerCase()}</Typography> : null}

            </CardContent>

            <CardActions disableSpacing>
                <Button className={styles.foodDialogClose} size="small" color="primary" onClick={() => closeDialog(true)}>
                    {t("algemeen.Sluit")}
                </Button>
            </CardActions>
        </Card>
    </Dialog >
}

export default FoodInformationDialogComponent