import {Typography} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {Diet, Profile} from "../../modules/profile/ProfileTypes";
import {useDispatch, useSelector} from "react-redux";
import {getDiets, getLanguages} from "../../modules/profile/ProfileActions";
import RenderProfile from "./RenderProfile";
import {ApplicationState} from "../../store";
import Box from "@mui/material/Box";

const ProfileComponent: React.FC = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation();


    let myProfileState = useSelector((state: ApplicationState) => state.profile)
    let myConfigState = useSelector((state: ApplicationState) => state.config)

    let activeProfile: Profile = Object.values(myProfileState.profiles).find(profile => profile.id === myProfileState.activeProfileID)

    const patientProfile = myProfileState.patient
    const guestProfiles = myProfileState.guests
    const featureChangeDiet = myConfigState.changeDiet
    const allDiets = myProfileState ? myProfileState.diets : null

    const getAllDiets = (profile: Profile) => {
        getDiets(profile)(dispatch)
    }

    const getCurrentLanguages = () => {
        getLanguages()(dispatch)
    }

    React.useEffect(() => {
        getAllDiets(activeProfile);
        getCurrentLanguages()
    }, [activeProfile]);


    return (<Box sx={{m: '2em'}}>
        <Typography
            sx={{textAlign: 'left', fontWeight: '600'}}>{t("profiel.Patiënt").toUpperCase()}</Typography>
        {renderPatient(patientProfile, featureChangeDiet, allDiets, patientProfile, activeProfile)}

        {guestProfiles && guestProfiles.length > 0 &&
            <Typography sx={{
                textAlign: 'left',
                marginTop: '2em',
                fontWeight: '600'
            }}>{t("profiel.Gasten")}</Typography>
        }
        {guestProfiles.map(guest => renderGuest(guest, featureChangeDiet, allDiets, patientProfile, activeProfile))}
    </Box>)
}

export default ProfileComponent

const renderPatient = (profile: Profile, featureChangeDiet: boolean, allDiets: Diet[], patientProfile: Profile, activeProfile: Profile) => {
    return <RenderProfile allDiets={allDiets} featureChangeDiet={featureChangeDiet} patientProfile={patientProfile}
                          profile={profile} activeProfile={activeProfile}/>

}

const renderGuest = (profile: Profile, featureChangeDiet: boolean, allDiets: Diet[], patientProfile: Profile, activeProfile: Profile) => {
    return <Box key={profile.id} sx={{mb: '25px'}}>
        <RenderProfile allDiets={allDiets} featureChangeDiet={featureChangeDiet} patientProfile={patientProfile}
                       profile={profile} activeProfile={activeProfile}/>
    </Box>
}