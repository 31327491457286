import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from '@mui/material/ListItemAvatar';

import * as React from "react";
import { ProfileAvatar } from "./ProfileAvatar";
import { Profile } from "../../modules/profile/ProfileTypes";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

interface SelectProfileProps {
    patient: Profile,
    guests: Profile[],
    onSelectProfile: (profile: Profile) => void;
}

interface RenderProfileProps {
    profile: Profile
    onSelectProfile: (profile: Profile) => void;
}

const RenderProfile: React.FC<RenderProfileProps> = ({profile, onSelectProfile}) => {
    const {t, i18n} = useTranslation()
    const secondaryText = profile.isPrimary ? t("profiel.Patiënt") : t("profiel.Gast");
    return <ListItemButton key={profile.id}
                     onClick={() => {
                         i18n.changeLanguage(profile.preferredLanguageCulture);
                         onSelectProfile(profile);
                     }
                     }>
        <ListItemAvatar>
            <ProfileAvatar profile={profile}/>
        </ListItemAvatar>
        <ListItemText primary={profile.name} secondary={secondaryText} />
    </ListItemButton>
}


export const SelectProfileComponent: React.FC<SelectProfileProps> = ({ patient, guests, onSelectProfile }) => {
        return (
            <Box sx={{ alignContent: 'center', pl: '20px' }}>
            <List component="nav">
                <RenderProfile profile={patient} onSelectProfile={onSelectProfile} />
                {guests.map((guest, i) => (
                    <RenderProfile key={i} profile={guest} onSelectProfile={onSelectProfile} />
                ))}
            </List>
        </Box>
        )

}

