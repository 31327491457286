import { IconButton, Link, Box } from "@mui/material";
import * as React from "react";
import LogoCsam from "./logo-csam.png";
import {useTranslation} from 'react-i18next';

const CsamLoginComponent:React.FC = () => {
    const {t} = useTranslation();

    return <>
            <Link href="/account/csam">
                <span>{t("csam.Aanmelden")}</span>
            </Link>
    
            <IconButton href="/account/csam">
                <Box component={'img'} src={LogoCsam} sx={{maxWidth: "80%"}} />
                </IconButton>
            </>
}
export default CsamLoginComponent