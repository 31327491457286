import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {ReactComponent as BreakIcon} from "../../../images/break.svg";
import {ReactComponent as BreakfastIcon} from "../../../images/breakfast.svg";
import {ReactComponent as BreakfastIconActive} from "../../../images/breakfast_done.svg";
import {ReactComponent as BreakfastIconNoMeal} from "../../../images/breakfast_nomeal.svg";
import {ReactComponent as BreakIconActive} from "../../../images/break_done.svg";
import {ReactComponent as BreakIconNoMeal} from "../../../images/break_nomeal.svg";
import {ReactComponent as DinnerIcon} from "../../../images/dinner.svg";
import {ReactComponent as DinnerIconActive} from "../../../images/dinner_done.svg";
import {ReactComponent as DinnerIconNoMeal} from "../../../images/dinner_nomeal.svg";
import {ReactComponent as LunchIcon} from "../../../images/lunch.svg";
import {ReactComponent as LunchIconActive} from "../../../images/lunch_done.svg";
import {ReactComponent as LunchIconNoMeal} from "../../../images/lunch_nomeal.svg";
import {MealMoment} from "../../../modules/order/OrderTypes";
import styles from "./MealMoment.module.css";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

export interface FoodButtonProps {
    data: MealMoment,
    selectMealMoment: any,
    logo: number,
}

export enum IconEnum {
    Breakfast,
    Lunch,
    Dinner,
}

const MealMomentButtonComponent: React.FC<FoodButtonProps> = ({data, selectMealMoment}) => {
    const {t} = useTranslation();
    const title = data.isBBF ? data.title + " - " + t("bbf.Buffet") : data.title;
    const description = data.description.indexOf("dd.") < 0 ? data.description : data.description.replace("dd.", t("day." + data.dayName));
    const splitDescription = description.split('\n').map((str, i) =>
        <Box component={'span'} sx={{display: 'block'}} key={i}>{str.indexOf("|tfrsc") < 0 ? str : t("resource." + str)}</Box>); //str


    const getNormalIcon = (id: number) => {
        switch (id) {
            case 1: //IconEnum.Breakfast:
                return <BreakfastIcon className={styles.breakFast}/>;
            case 3: //IconEnum.Lunch:
                return <LunchIcon/>;
            case 5: //IconEnum.Dinner:
                return <DinnerIcon/>;
            default:
                return <BreakIcon/>
        }
    }

    const getActiveIcon = (id: number) => {
        switch (id) {
            case 1: //IconEnum.Breakfast:
                return <BreakfastIconActive className={styles.breakFast}/>;
            case 3: //IconEnum.Lunch:
                return <LunchIconActive/>;
            case 5: //IconEnum.Dinner:
                return <DinnerIconActive/>;
            default:
                return <BreakIconActive/>
        }
    }

    const getNoMealIcon = (id: number) => {
        switch (id) {
            case 1: //IconEnum.Breakfast:
                return <BreakfastIconNoMeal className={styles.breakFast}/>;
            case 3: //IconEnum.Lunch:
                return <LunchIconNoMeal/>;
            case 5: //IconEnum.Dinner:
                return <DinnerIconNoMeal/>;
            default:
                return <BreakIconNoMeal/>
        }
    }

    const getIcon = (id: number) => {
        if (data.isNoMeal) {
            return getNoMealIcon(id);
        } else {
            if (data.isOrdered) {
                return getActiveIcon(id);
            } else {
                return getNormalIcon(id);
            }
        }
    }

    return <Button className={[styles.foodButton, data.toggle ? null : styles.foodButtonDisabled].join(" ")}
                   variant="contained" onClick={() => selectMealMoment(data)} >
        <Box component={'span'} sx={
            {backgroundColor: theme => data.isNoMeal ? theme.cancelComponents.main : data.isOrdered ? theme.mealMoment.active : theme.mealMoment.main}
        }
             className={styles.imageContainer}>
            {getIcon(data.id)}
        </Box>
        <Box>
            <Typography id="title" variant="h6">{title}</Typography>
            <Typography id="title" variant="body2" sx={{marginInlineEnd: '1em'}}>
                {splitDescription}
            </Typography>
        </Box>
    </Button>
}

export default MealMomentButtonComponent;
