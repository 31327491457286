import * as React from "react";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import styles from "./DailyMenu.module.css";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {ExtraItem} from "../../../modules/order/OrderTypes";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {confirmMenu, showDailyMenu} from "../../../modules/order/OrderActions";
import FoodItemExtraComponent from "./FoodItemExtraComponent";
import FoodItemComponent from "../FoodItemComponent";
import ConfirmDialog from "../../dialog/ConfirmationDialog";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const DailyMenuComponent: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const state = useSelector((state: ApplicationState) => state)
    const myMenuState = state.menu
    const myOrderStat = state.order
    const myDailyMenu = myMenuState.menu.dailyMenu;
    const foodItemsExtraState = myOrderStat.foodItemsExtra;

    const myFoodItemsChartNumbers: number[] = Object.keys(myDailyMenu).map((key) => {
        const index = key as unknown as number
        return myDailyMenu[index].menuChartLineNr
    })

    const sortMyFoodItemsChartNumbers: number[] = myFoodItemsChartNumbers.sort((n1, n2) => n1 - n2)

    const foodItems: number[] = sortMyFoodItemsChartNumbers.flatMap((value) => {
        const objArr = Object.keys(myDailyMenu).map((key) => {
            const index = key as unknown as number
            if (myDailyMenu[index].menuChartLineNr === value) {
                return myDailyMenu[index].foodItemID
            }
            return undefined
        })
        return objArr.length == 1 && objArr[0] == 0 ? objArr : objArr.filter(e => !!e)
    })

    const foodItemsExtra: number[] = foodItemsExtraState && Object.keys(foodItemsExtraState).map(key => {
        const index = key as unknown as number
        const extraItem = foodItemsExtraState[index] as unknown as ExtraItem
        return extraItem.id
    })

    const portions = myDailyMenu
    const loading = myMenuState.loading
    const mealMomentIsOrdered = myOrderStat.mealMoment.isOrdered
    const mealMomentIsBlocked = myOrderStat.mealMoment.blocked
    const mealMoment = myOrderStat.mealMoment

    // const mealMomentReadOnly =  !myOrderStat.mealMoment.isBBFSelectable


    const [openOrder, setOpenOrder] = React.useState<boolean>(false)
    const [openNoOrder, setOpenNoOrder] = React.useState<boolean>(false)
    const style = foodItems.length > 0 && mealMomentIsOrdered ? {display: 'none'} : {};
    const isDisabled = mealMomentIsBlocked || (mealMoment.isBBF && !mealMoment.isBBFSelectable);


    const HandleConfirmMenu = () => {
        confirmMenu(false, true)(dispatch, state).then((e) => {
            if (e) navigate('/menu');
        })
        //navigate('/menu');

    }
    const confirmNoMenu = () => {
        confirmMenu(true, true)(dispatch, state).then((e) => {
            if (e) navigate('/menu');
        })
        //navigate('/menu');
    }

    const handleClickOpenOrder = () => {
        setOpenOrder(true)
    };

    const handleClickOpenNoOrder = () => {
        setOpenNoOrder(true)
    };

    const handleClose = () => {
        setOpenOrder(false)
        setOpenNoOrder(false)
        // setIsDailyMenu(false)
    };

    const handleConfirmOrder = () => {
        HandleConfirmMenu();
        handleClose();
    }

    const handleConfirmNoOrder = () => {
        confirmNoMenu();
        handleClose();
    }

    const handleShowDailyMenu = () => {
        showDailyMenu(mealMoment)(dispatch)
        // setIsDailyMenu(true)
    }

    if (loading) return <CircularProgress/>;

    const confirmationNoMeuDialog = () => {
        return <ConfirmDialog title={t("order.DmenuGeen")} closeText={t("algemeen.Annuleer")} confirmText={t("algemeen.Bevestigen")} handleConfirm={handleConfirmNoOrder} message={t("order.DmenuBestelniet")} open={openNoOrder} handleClose={handleClose}/>
    }

    const confirmationDialog = () => {
        return <ConfirmDialog title={t('order.DmenuDag')} closeText={t("algemeen.Annuleer")} confirmText={t("algemeen.Bevestigen")} handleConfirm={handleConfirmOrder} message={t("order.DmenuBestel")} open={openOrder} handleClose={handleClose}/>
    }

    if (foodItems.length === 0 && mealMomentIsOrdered) {
        return <Box>
            <List id={styles.list}>
                <Typography variant="body1">{t("order.DmenuNiets")}</Typography>
            </List>
            {foodItemsExtra && foodItemsExtra.length > 0 &&
                < Typography variant="caption">{t("order.DmenuExtra")}</Typography>
            }
            {foodItemsExtra && foodItemsExtra.length > 0 &&
                <Paper elevation={1} square={true}>
                    <List id={styles.list}>
                        {
                            foodItemsExtra.map((food: number, i: number) => {
                                if (foodItemsExtra.length !== i + 1) //if not last item return element with divider
                                {
                                    return <FoodItemExtraComponent id={food} key={i} divider={true}/>
                                } else return <FoodItemExtraComponent id={food} key={i} divider={false}/>
                            })
                        }
                    </List>
                </Paper>
            }

            <Button id={styles.button} variant="contained" color="primary" style={style}
                    onClick={handleClickOpenOrder} disabled={isDisabled}>
                {t("order.DmenuDag")}
            </Button>
            {confirmationDialog()}

            {foodItemsExtra && foodItemsExtra.length > 0 &&
                <Button id={styles.button} variant="contained"
                        sx={(theme) => mealMomentIsBlocked ? {} : {
                            backgroundColor: theme.cancelComponents.main,
                            color: theme.cancelComponents.contrastText,
                        }
                        }
                        onClick={handleClickOpenNoOrder} disabled={isDisabled}>
                    {t("order.DmenuGeen")}
                </Button>
            }
            {confirmationNoMeuDialog()}
        </Box>;
    } else
        if (foodItems.length > 1 || (foodItems.length === 1 && foodItems[0] !== 0)) {
        return <Box>
            <Paper elevation={1} square={true}>
                <List id={styles.list}>
                    {
                        foodItems.map((id: number, i) => {

                            //if not last item return element with divider
                            let showDivider = foodItems.length !== i + 1;
                            let portion = portions[id];
                            let amount = 0;
                            if (portion) {
                                amount = portion.portion;
                            }

                            return <FoodItemComponent id={id} key={i} showDivider={showDivider} amount={amount}
                                mealMomentIsBlocked={mealMomentIsBlocked} showNutrientIcon={true} />
                        })
                    }
                </List>
            </Paper>
            {foodItemsExtra && foodItemsExtra.length > 0 &&
                <Typography variant="caption">EXTRA'S</Typography>
            }
            {foodItemsExtra && foodItemsExtra.length > 0 &&
                <Paper elevation={1} square={true}>
                    <List id={styles.list}>
                        {
                            foodItemsExtra.map((food: number, i: number) => {
                                if (foodItemsExtra.length !== i + 1) //if not last item return element with divider
                                {
                                    return <FoodItemExtraComponent id={food} key={i} divider={true}/>
                                } else return <FoodItemExtraComponent id={food} key={i} divider={false}/>
                            })
                        }
                    </List>
                </Paper>
            }

            <Button id={styles.button} variant="contained" color="primary" sx={style}
                    onClick={handleClickOpenOrder} disabled={isDisabled}>
                {t("order.DmenuDag")}
            </Button>
            {confirmationDialog()}

            <Button id={styles.noMealButton} className={styles.noMealButton} variant="contained"
                    sx={(theme) => mealMomentIsBlocked ? {} : {
                        backgroundColor: theme.cancelComponents.main,
                        color: theme.cancelComponents.contrastText,
                    }
                    }
                    onClick={handleClickOpenNoOrder} disabled={isDisabled}>
                {t("order.DmenuGeen")}
            </Button>
            {confirmationNoMeuDialog()}

        </Box>;
    }
    // When a user specified he did not want a meal, the backend will return a daily menu with 1 empty item ->
    else
        if (foodItems.length === 1 && foodItems[0] === 0) {
        return <Box>
            <Paper elevation={1} square={true}>
                <List id={styles.list}>
                    {
                        <Typography variant="body1">{t("order.DmenuGmlt")}</Typography>
                    }
                </List>
            </Paper>
            <Button id={styles.button} variant="contained" color="primary" onClick={handleShowDailyMenu}
                    disabled={mealMomentIsBlocked}>
                {t("order.Mvd")}
            </Button>
            {confirmationDialog()}
        </Box>;
        }
        else {
        return <Box>
            <Paper elevation={1} square={true}>
                <List id={styles.list}>
                        <Typography variant="body1">{mealMoment.isBBF ? t("bbf.NietGekozen") : t("order.Cmenu")}</Typography>
                </List>
            </Paper>
            <Button id={styles.button} variant="contained"
                    sx={(theme) => mealMomentIsBlocked ? {} : {
                        backgroundColor: theme.cancelComponents.main,
                        color: theme.cancelComponents.contrastText,
                    }
                    }
                    onClick={handleClickOpenNoOrder} disabled={isDisabled}>
                {t("order.DmenuGeen")}
            </Button>
            {confirmationNoMeuDialog()}
        </Box>;
        }
}

export default (DailyMenuComponent);
