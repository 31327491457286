import axios from "axios";
import {normalize, schema} from "normalizr";
import {MealMoment} from '../OrderTypes';
import {DiscriminationConfiguration, DiscriminationRule} from './discriminationTypes';

export const discriminationAction = {
    REQUEST_DISCRIMINATIONRULES: "REQUEST_DISCRIMINATIONRULES",
    RECEIVE_DISCRIMINATIONRULES: "RECEIVE_DISCRIMINATIONRULES"
}
 
export const getDiscriminationRules = (mealMoment: MealMoment) => {
    return (dispatch: any) => {
        return axios({
            url: "/api/discrimination/rules",
            method: "get",
            headers: { 'content-type': "application/json" },
            responseType: "json",
            params: {
                nutrition: mealMoment.diet.nutritionNr,
                diet: mealMoment.diet.dietNr,
                mealMoment: mealMoment.id
            }
        })
            .then(response => {
                let configuration = responseToDiscriminationConfiguration(response.data);
                dispatch({
                    type: discriminationAction.RECEIVE_DISCRIMINATIONRULES,
                    payload: configuration
                });
            });
    }
}

function responseToDiscriminationConfiguration(response: any): DiscriminationConfiguration {

    let exclusingSchema = new schema.Entity("exclusions", {}, {idAttribute: "exclusionEntryNr"});

    let exclusingFoodItemSetSchema = new schema.Entity("exclusionsFoodItemSet", {}, {idAttribute: "id"});

    let discriminationRuleSchema = new schema.Entity("rules", {
        exclusions: [exclusingSchema],
        exclusionsFoodItemSet: [exclusingFoodItemSetSchema]
    }, {
        idAttribute: "setNr",
        processStrategy: (entity: DiscriminationRule): DiscriminationRule => {
            return {
                ...entity
            };
        }
    });

    let result = normalize(response, {
        rules: [discriminationRuleSchema],
        exclusions: [exclusingSchema],
        exclusionsFoodItemSet: [exclusingFoodItemSetSchema]
    });

    return {
        rules: result.entities.rules,
        rulesList: result.result.rules,
        exclusions: result.entities.exclusions,
        exclusionsList: result.result.exclusions,
        exclusionsFoodItemSetList: result.entities.exclusionsFoodItemSet
    };
}