import * as React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {Diet, Profile} from "../../modules/profile/ProfileTypes";
import {saveProfile} from "../../modules/profile/ProfileActions";
import {Checkbox, Paper, SelectChangeEvent, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import styles from "./Profile.module.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {ProfileAvatar} from "./ProfileAvatar";
import ListItemText from "@mui/material/ListItemText";
import {PropsWithChildren} from "react";
import Box from "@mui/material/Box";

interface RenderProfileProps {
    profile: Profile
    featureChangeDiet: boolean
    allDiets: Diet[]
    patientProfile: Profile
    activeProfile: Profile
}
const RenderProfile: React.FC<RenderProfileProps> = ({profile, featureChangeDiet, allDiets, patientProfile, activeProfile}) => {
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation();

    let isUsingApp;
    // let languagePreference;
    let dietPreference;

    const saveCurrentProfile = (patientNumber: string, isDisclaimerChecked: boolean, preferredDietNumber: number, preferredLanguage: number, isUsingApp: boolean, newCulture: string, profile: Profile) => {
        saveProfile(patientNumber, isDisclaimerChecked, preferredDietNumber, preferredLanguage, isUsingApp, newCulture, profile)(dispatch)
    }

    const handleIsUsingAppChange = (event: React.ChangeEvent<HTMLInputElement>, profile: Profile) => {
        const isUsingApp = event.target.checked as boolean;

        saveCurrentProfile(profile.id, true, profile.preferredDiet, profile.preferredLanguage, !isUsingApp, profile.preferredLanguageCulture, activeProfile);
    }

    const handleDietChange = (event: SelectChangeEvent<number>, profile: Profile) => {

        const diet = event.target.value as number;
        saveCurrentProfile(profile.id, true, diet, profile.preferredLanguage, !profile.isUsingApp, profile.preferredLanguageCulture, activeProfile);
    }

    // TODO : translation label 'IsUsingApp -> {i18n.t("profiel.UseApp")} ! Dit werkt bij testen in development MAAR werkt NIET na build en release op Azure !!
    // dan staat er gewoon 'profiel.UseApp' ?! Werkt ook niet met this.props.t("profiel.UseApp")
    // misschien omdat het de laatste lijn is in de translation.json in de sectie 'profiel' ?
    // misschien omwile van checkbox?
    // <Typography> + <Ckeckbox> werkt wel maar dan is de tekst gecentreerd in het midden (zie checkbox van 'disclaimer')
    //this.props.isUsingAppTxt

    if (profile.isPrimary) {
        if (profile.isUsingAppTxt != null) {
            isUsingApp = <SettingsListBoxItemComponent label={profile.isUsingAppTxt}>
                <Checkbox
                    disabled={!profile.disclaimerAccepted}
                    checked={profile.isUsingApp}
                    color="primary"
                    onChange={(e) => handleIsUsingAppChange(e, profile)}
                />
            </SettingsListBoxItemComponent>;
        }
    }

    const activeDiet = <SettingsListBoxItemComponent label={t("profiel.ToeDieet")}>
        <Typography align='left'><strong>{profile.actualDietNames.find(x => x.culture.toLowerCase().trim() === i18n.language.toLowerCase().trim()).name}</strong></Typography>
    </SettingsListBoxItemComponent>;

    //31/08/2022 : Hide preferredLanguage in Profile
    //if (this.props.featureTranslationActive && profile.isPrimary && this.props.allLanguages) {
    //    languagePreference = <SettingsListBoxItemComponent label={this.props.t("profiel.PrefLanguage")}>
    //        <FormControl>
    //            <Select disabled={!profile.disclaimerAccepted} style={{ minWidth: '10vw', maxWidth: '50vw' }} value={profile.preferredLanguage ? profile.preferredLanguage : ''}
    //                onChange={(e) => this.handleLanguageChange(e, profile)}>
    //                {
    //                    this.props.allLanguages.map((value, index) =>
    //                        <MenuItem className={styles.languageItem} key={index} value={value.id}>{value.name}</MenuItem>
    //                    )
    //                }
    //            </Select>
    //        </FormControl>
    //    </SettingsListBoxItemComponent>
    //}

    //08/02/2021 : ... Not (!profile.isPrimary && this.props.featureChangeDiet && this.props.allDiets && this.props.patientProfile.disclaimerAccepted) || (profile.isPrimary && ..
    //Same for Primary patient as for guests
    if (profile.newPatientAdaptDiet && featureChangeDiet && allDiets && patientProfile.disclaimerAccepted) {
        dietPreference = <SettingsListBoxItemComponent label={t("profiel.VoorDieet")}>
            <FormControl>
                <Select sx={{minWidth: '10vw', maxWidth: '50vw'}}
                        value={profile.preferredDiet ? profile.preferredDiet : ''}
                        onChange={(e) => handleDietChange(e, profile)}>
                    {
                        allDiets.map((value, index) =>
                            <MenuItem className={styles.dietItem} key={index}
                                      value={value.id}>{value.name.toLowerCase()}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        </SettingsListBoxItemComponent>
    }

    return <Paper square elevation={1}>
        <List>
            <ListItem key={profile.id} sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <Box display={'flex'}>
                    <ListItemAvatar>
                        <ProfileAvatar profile={profile}/>
                    </ListItemAvatar>
                    <ListItemText primary={profile.name} sx={{alignSelf: 'center'}}/>
                </Box>
            </ListItem>
            <Box sx={{ml: '2em', mr: '1em'}}>
                {isUsingApp}
                {activeDiet}
                {/*{languagePreference}*/}
                {dietPreference}
            </Box>

        </List>
    </Paper>
}

export default RenderProfile

interface SettingsListBoxItemProps {
    label: string;
}

const SettingsListBoxItemComponent: React.FC<SettingsListBoxItemProps & PropsWithChildren> = ({children, label}) => {
    return <ListItem sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography align='left'>{label}</Typography>
        <Box sx={{ml: '2em'}}>
            {children}
        </Box>
    </ListItem>
}

