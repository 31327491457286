import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import languageDetector, {CookieOptions} from 'i18next-browser-languagedetector';

const cookie: CookieOptions = {
    path: '/', sameSite: 'strict'
}

const DETECTION_OPTIONS = {
    // order and from where user language should be detected
    order: ['localStorage'], //order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    //NOT navigator -> See productowner

              lookupQuerystring: 'lng',
              lookupCookie: 'i18next',
              lookupLocalStorage: 'i18nextLng',
        //    lookupSessionStorage: 'i18nextLng',
        //    lookupFromPathIndex: 0,
        //    lookupFromSubdomainIndex: 0,

    //    // cache user language on
    caches: ['localStorage'], //caches: ['localStorage', 'cookie'],
              excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

        //    // optional expire and domain for set cookie
              cookieMinutes: 10,
              //cookieDomain: 'myDomain',

        //    // optional htmlTag with lang attribute, the default is:
        //    htmlTag: document.documentElement,

        //    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
              cookieOptions: cookie
};

i18n
    // load translation using xhr -> see /public/locales
    // learn more:  https://github.com/i18next/i18next-http-backend // Deprecated -> https://github.com/i18next/i18next-xhr-backend
    //.use(HttpApi)
    .use(backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(languageDetector)
    // pass the i18n instance to the react-i18next components.
    // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options 
    .init({
        load: 'languageOnly', // 'nl'ipv 'nl-BE'
        fallbackLng: 'nl', //'nl', 'en', 'fr'
        ns: 'translation',
        defaultNS: "translation",          

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        //whitelist: ['nl', 'fr', 'en'],

        backend: {
            loadPath: '/api/storage/{{ns}}/?lng={{lng}}', // '/locales/{{lng}}/{{ns}}.json' ipv './locales/{{lng}}/{{ns}}.json'
            // exp. cegekatest.cmealmaaltijden.com = ROOT (ClientApp)
            // ./ starts from current route (url) (expl: cegekatest.cmealmaaltijden.com/account if on login-page) (searches for .../account/locales/...)
            //  / starts always from the ROOT (searches for .../locales/..)

        },

        //Options for 'LanguageDetector'
        detection: DETECTION_OPTIONS,
        
        // special options for react-i18next
        // learn more: https://react.i18next.com/components/i18next-instance
        react: {
            // wait: false, //DH, 30/06/2021 : Deprecated in new versions
            useSuspense: true,
        },
        
    }, (err) => {
            if (err) {
                //Do somthing?
            }
    });

export default i18n;