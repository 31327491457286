import * as React from "react";
import OrderViewComponent from "./OrderViewComponent";
import {clearOrder} from "../../modules/order/OrderActions";
import {useDispatch} from "react-redux";

const OrderComponent: React.FC = () => {
    const dispatch = useDispatch()
    const unloadOrder = () => {
        dispatch(clearOrder());
    }

    React.useEffect(() => {

        // returned function will be called on component unmount
        return () => {
            unloadOrder()
        }
    })
    return <OrderViewComponent />
}

export default OrderComponent

