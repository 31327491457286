import * as React from "react";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./CustomMenu.module.css";
import {useTranslation} from "react-i18next";
import {MenuType} from "../../../modules/order/OrderTypes";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {discriminateFoodCourse} from "../../../modules/order/discriminition/discriminationSelectors";
import {goNextStep, selectMenu} from "../../../modules/order/OrderActions";
import FoodCourseGroupComponent from "./FoodCourseGroupComponent";
import Box from "@mui/material/Box";

export interface FoodCourseProps {
    id: number
    isExceeded: boolean
    expanded: boolean
}

const FoodCourseComponent: React.FC<FoodCourseProps> = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const state = useSelector((state: ApplicationState) => state)
    const myMenuState = state.menu
    const myOrderState =state.order
    const myCourse = myMenuState.menu.courses[props.id];
    const courseGroupIDs = myCourse.courseGroups
    let captionResult = discriminateFoodCourse(props.id, state);

    const title = myCourse.title
    const isExceeded = myOrderState.mealMoment.isBBF && !myOrderState.mealMoment.isBBFSelectable ? false : captionResult.isExceeded
    const expanded = (myOrderState.expandedCoursePanel === 0 ? Object.keys(myMenuState.menu.courses)[0] === props.id.toString() : myOrderState.expandedCoursePanel === props.id) || props.expanded

    const selectCoursePanel = () => {
        selectMenu(MenuType.CUSTOM_MENU, props.id, null)(dispatch, myMenuState)
    }

    const nextStep = (id: number) => {
        goNextStep(id)(dispatch, state)
    }


    return <Accordion expanded={expanded} onChange={selectCoursePanel}>
        <AccordionSummary className={expanded ? styles.panelHeader : null} expandIcon={<ExpandMoreIcon/>}>
            <Typography className={styles.courseTitle} color={isExceeded ? "error" : "initial"}
                        variant="h6">{title.toLowerCase()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box className={styles.foodContainer}>
                {
                    courseGroupIDs.map((groupId, i) =>
                        <FoodCourseGroupComponent courseID={props.id} courseGroupID={groupId} key={i}/>
                    )
                }
                <Button id={styles.button} variant="contained" color="secondary"
                        onClick={() => nextStep(props.id)}>{t("algemeen.Volgende")}</Button>
            </Box>
        </AccordionDetails>
    </Accordion>
}

export default FoodCourseComponent