import i18n from 'i18next';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../../store';
import { MenuState } from "../../menu/MenuTypes";
import { ItemPortion, OrderState } from "../OrderTypes";
import { DiscriminationConfiguration, DiscriminationRule } from './discriminationTypes';

const configuration = (state: ApplicationState): DiscriminationConfiguration => {
    return state.discrimination.configuration;
}
// const foodItems = (state: ApplicationState): number[] => state.menu.menu.foodItemKeys;

const orderedFoodItemSelector = (state: ApplicationState): number[] => {
    return Object.keys(state.order.itemPortions).map(Number);
};


//We did this (foodItemId = 100000000 * item.componentId + item.entryNr) to make entryNr uniek because the same entryNr can appear in more then one CourseGroup!
//So we have to UNDO this here to check execptionrules based on foodItemId!
const convertedFoodItemsSelector = (orderedFoodItems: number[]) => {
    let convertedFoodItems: number[] = [];
    orderedFoodItems.forEach(id => {
        let idAsString = id.toString();
        idAsString = idAsString.substr(idAsString.length - 8);
        let idAsNumber = Number(idAsString);
        convertedFoodItems.push(idAsNumber);
    });
    return convertedFoodItems;
};

interface DependencyResult {
    setNr?: number;
    components: number[];
    helpText: string;
    isMaximum: boolean;
    isExceeded: boolean;
}

//Rules
export const ruleToExcludeSelector = createSelector(
    configuration,
    orderedFoodItemSelector,
    (config, orderedFoodItems) => {
        let results: DiscriminationRule[] = [];
        let convertedFoodItems: number[] = convertedFoodItemsSelector(orderedFoodItems);
        let sources = convertedFoodItems.filter(x => config.exclusionsList.indexOf(x) > -1);
        if (sources) {
            sources.forEach(source => {
                let exclusion = config.exclusions[source];
                exclusion.discriminationSets.forEach(setNr => {
                    let rule = config.rules[setNr];
                    results = results.concat(rule);
                });
            });
        }
        return results;
    }
)

export const ruleOverDiscriminationPortionSelector = createSelector(
    configuration,
    (state: ApplicationState) => state.discrimination.rulePortionCounter,
    (config, counters) => {
        let results: DiscriminationRule[] = [];
        config.rulesList.forEach(id => {
            let rule = config.rules[id];
            if (rule.maxima != null) {
                if (rule.maxima.maximumPortions >= 0) {
                    let rulePortions = counters[id];
                    if (rulePortions >= rule.maxima.maximumPortions) {
                        results = results.concat(rule);
                    }
                }
            }
        });
        return results;
    }
)

//(state: ApplicationState) => state.discrimination.ruleTypeCounter,
export const ruleOverDiscriminationTypeSelector = createSelector(
    configuration,
    (state: ApplicationState) => state.discrimination.ruleTypeCounter,
    orderedFoodItemSelector,
    (config, counters) => {
        let results: DiscriminationRule[] = [];
        config.rulesList.forEach(id => {
            let rule = config.rules[id];
            if (rule.maxima != null) {
                if (rule.maxima.maximumTypes >= 0) {
                    let ruleTypes = counters[id];
                    if (ruleTypes > 0 && ruleTypes >= rule.maxima.maximumTypes) {
                        results = results.concat(rule);
                    }
                }
            }
        });
        return results;
    }
)

export const ruleOverDependencyPortionSelector = createSelector(
    configuration,
    (state: ApplicationState) => state.discrimination.rulePortionCounter,
    (config, counters) => {
        let results: DependencyResult[] = [];

        config.rulesList.forEach(id => {
            let sourcerule = config.rules[id];
            sourcerule.dependencies.forEach(depset => {
                if (depset.sourceMinimumPortion <= counters[id] && counters[id] <= depset.sourceMaximumPortion) {
                    let rulePortions = counters[depset.targetDependencySetNr];
                    let dependencyResult: DependencyResult = { setNr: null, components: null, helpText: null, isMaximum: null, isExceeded: null };
                    let targetrule = config.rules[depset.targetDependencySetNr];

                    dependencyResult.setNr = depset.targetDependencySetNr;
                    dependencyResult.components = targetrule.components;

                    if (rulePortions < depset.targetMaximumPortion) {
                        dependencyResult.helpText = i18n.t("disc.Nog1") + " " + (depset.targetMaximumPortion - rulePortions).toString() + " " + i18n.t("disc.Nog2");
                        dependencyResult.isExceeded = false;
                        dependencyResult.isMaximum = false;
                    }
                    if (rulePortions === depset.targetMaximumPortion) {
                        dependencyResult.helpText = i18n.t("disc.Geen");
                        dependencyResult.isExceeded = false;
                        dependencyResult.isMaximum = true;
                    }
                    if (rulePortions > depset.targetMaximumPortion) {
                        dependencyResult.helpText = i18n.t("disc.Teveel");
                        dependencyResult.isExceeded = true;
                        dependencyResult.isMaximum = false;
                    }

                    results = results.concat(dependencyResult);
                }
            })
        });
        return results;
    }
)


//FoodItemSelectors

export const foodItemsToExcludeSelector = createSelector(
    configuration,
    ruleToExcludeSelector,
    (config, ruleToExclude) => {
        let results: number[] = [];
        ruleToExclude.forEach(rule => {
            results = results.concat(rule.foodItems);
        });
        return results;
    }
)

export const foodItemsFromSetToExcludeSelector = createSelector(
    (state: ApplicationState) => state,
    configuration,
    orderedFoodItemSelector,
    (state, config, orderedFoodItems) => {
        let results: number[] = [];
        let forbiddenFoodItems: number[] = [];
        for (let key in orderedFoodItems) {
            let orderedFoodItem = orderedFoodItems[+key];
            let ruleByFoodItemSet = state.discrimination.ruleByFoodItemSet;
            for (let foodItemSetKey in ruleByFoodItemSet) {
                if (orderedFoodItem === +foodItemSetKey) {
                    forbiddenFoodItems = ruleByFoodItemSet[+foodItemSetKey];
                    for (let key in forbiddenFoodItems) {
                        if (results.indexOf(forbiddenFoodItems[+key]) < 0) {
                            results.push(forbiddenFoodItems[+key]);
                        }
                        //let courseGroup = state.menu.menu.courseGroups[+key];
                        //courseGroup.foodItems.forEach(foodItemNr => {
                        //    let convertedFoodItem = foodItemNr - (100000000 * courseGroup.id);
                        //    if (forbiddenFoodItems.indexOf(convertedFoodItem) >= 0) {
                        //        results.concat(foodItemNr);
                        //    }
                        //});
                    }
                }
            }
        }
        return results;
    }
)


export const foodItemsOverDiscriminationPortionSelector = createSelector(
    configuration,
    ruleOverDiscriminationPortionSelector,
    (config, ruleOverDiscriminationPortion) => {
        let results: number[] = [];
        ruleOverDiscriminationPortion.forEach(rule => {
            results = results.concat(rule.foodItems);
        });
        return results;
    }
)

const itemPortions = (state: ApplicationState): { [key: number]: ItemPortion } => state.order.itemPortions;

export const foodItemsOverDiscriminationTypeSelector = createSelector(
    configuration,
    ruleOverDiscriminationTypeSelector,
    itemPortions,
    (config, ruleOverDiscrimination, itemPortions) => {
        let results: number[] = [];
        ruleOverDiscrimination.forEach(rule => {
            rule.foodItems.forEach(foodItem => {
                let portion = itemPortions[foodItem];
                if (!portion || portion.portion === 0) {
                    //only add (disable) foodItem without registered portion
                    results.push(foodItem);
                }
            });

        });

        return results;
    }
)

export const foodItemsOverDependencyPortionSelector = createSelector(
    configuration,
    ruleOverDependencyPortionSelector,
    (config, ruleOverDependency) => {
        let results: number[] = [];
        ruleOverDependency.forEach(ruleNumber => {
            if (ruleNumber.isExceeded || ruleNumber.isMaximum) {
                let deprule = config.rules[ruleNumber.setNr];
                results = results.concat(deprule.foodItems);
            }

        });
        return results;
    })

export interface FoodItemDiscriminationResult {
    isLocked: boolean;
    isLockedSet: boolean;
    lockedReason: string;
}

export const discriminateFoodItem = function (id: number, courseGroup: number, state: ApplicationState): FoodItemDiscriminationResult {

    let myMenuState = state.menu as MenuState;
    let item = myMenuState.menu.foodItems[id];
    // let myOderState = state.order as OrderState;

    let foodItemsToExclude = foodItemsFromSetToExcludeSelector(state);
    let convertedId = id - (100000000 * courseGroup);

    if (foodItemsToExclude.indexOf(convertedId) > -1) {
        return {
            isLocked: true,
            isLockedSet: true,
            lockedReason: item.description + " " + i18n.t("disc.Excl")
        }
    }

    foodItemsToExclude = foodItemsToExcludeSelector(state);

    if (foodItemsToExclude.indexOf(id) > -1) {
        return {
            isLocked: true,
            isLockedSet: false,
            lockedReason: item.description + " " + i18n.t("disc.Excl")
        }
    }

    foodItemsToExclude = foodItemsOverDiscriminationPortionSelector(state);
    if (foodItemsToExclude.indexOf(id) > -1) {
        return {
            isLocked: true,
            isLockedSet: false,
            lockedReason: item.description + " " + i18n.t("disc.MaxP")
        }
    }

    foodItemsToExclude = foodItemsOverDiscriminationTypeSelector(state);
    if (foodItemsToExclude.indexOf(id) > -1) {
        return {
            isLocked: true,
            isLockedSet: false,
            lockedReason: item.description + " " + i18n.t("disc.MaxS")
        }
    }

    foodItemsToExclude = foodItemsOverDependencyPortionSelector(state);
    if (foodItemsToExclude.indexOf(id) > -1) {
        return {
            isLocked: true,
            isLockedSet: false,
            lockedReason: item.description + " " + i18n.t("disc.Afha")
        }
    }

    return {
        isLocked: false,
        isLockedSet: false,
        lockedReason: null
    }

};


//FoodCourseGroupSelectors

export const foodCourseGroupToExcludeSelector = createSelector(
    configuration,
    ruleToExcludeSelector,
    (config, ruleToExclude) => {
        let results: number[] = [];
        ruleToExclude.forEach(rule => {
            results = results.concat(rule.components);
        });
        return results;
    }
)

export const foodCourseGroupOverDiscriminationPortionSelector = createSelector(
    configuration,
    ruleOverDiscriminationPortionSelector,
    (config, ruleOverDiscriminationPortion) => {
        let results: number[] = [];
        ruleOverDiscriminationPortion.forEach(rule => {
            results = results.concat(rule.components);
        });
        return results;
    }
)

export const foodCourseGroupOverDiscriminationTypeSelector = createSelector(
    configuration,
    ruleOverDiscriminationTypeSelector,
    (config, ruleOverDiscrimination) => {
        let results: number[] = [];
        ruleOverDiscrimination.forEach(rule => {
            results = results.concat(rule.components);
        });
        return results;
    }
)

export const foodCourseGroupOverDependencyPortionSelector = createSelector(
    configuration,
    ruleOverDependencyPortionSelector,
    (config, ruleOverDependency) => {
        let results: DependencyResult[] = [];
        ruleOverDependency.forEach(ruleNumber => {
            results = results.concat(ruleNumber);
        });
        return results;
    });


export interface FoodCourseGroupDiscriminationResult {
    isExceeded: boolean;
    helpText: string;
}

export const discriminateFoodCourseGroup = function (id: number, state: ApplicationState): FoodCourseGroupDiscriminationResult {

    let myMenuState = state.menu as MenuState;
    let myOrderState = state.order as OrderState;
    let isExceeded: boolean = false;
    
    let foodCourseGroupsToExclude = foodCourseGroupToExcludeSelector(state);
    if (foodCourseGroupsToExclude.indexOf(id) > -1) {

        //If the courseGroup is Excluded always give this error
        //Excluded has nothing to do with max portions or max types
        return {
            isExceeded: true,
            helpText: i18n.t("disc.GeenKies")
        }

        // isExceeded = myMenuState.menu.courseGroups[id].foodItems.some(item => {
        //     let _item = myOrderState.itemPortions[item];
        //     if (_item != null) {
        //         if (_item.portion > 0) {
        //             return true;
        //         }
        //     }
        //     return false
        // });
        // if (isExceeded) {
        //    return {
        //         isExceeded: true,
        //        helpText: i18n.t("disc.GeenKies")
        //    }
        // }
    }

    foodCourseGroupsToExclude = foodCourseGroupOverDiscriminationPortionSelector(state);
    if (foodCourseGroupsToExclude.indexOf(id) > -1) {

        isExceeded = myMenuState.menu.courseGroups[id].foodItems.some(item => {
            let _item = myOrderState.itemPortions[item];
            if (_item != null) {
                if (_item.portion > 0) {
                    return true;
                }
            }
            return false
        });
        if (isExceeded) {
            return {
                isExceeded: false,
                helpText: i18n.t("disc.MaxKiesP")
            }
        }
        
    }

    foodCourseGroupsToExclude = foodCourseGroupOverDiscriminationTypeSelector(state);
    if (foodCourseGroupsToExclude.indexOf(id) > -1) {

        return {
            isExceeded: false,
            helpText: i18n.t("disc.MaxKiesS")
        }
    }

    let dependencyFoodCourseGroupsToExclude = foodCourseGroupOverDependencyPortionSelector(state);
    let _helpText = null;
    dependencyFoodCourseGroupsToExclude.forEach(dependencyFoodCourseGroup => {
        if (dependencyFoodCourseGroup.components.indexOf(id) > -1) {
            _helpText = dependencyFoodCourseGroup.helpText;
            isExceeded = dependencyFoodCourseGroup.isExceeded;
        }
    })
    return {
        isExceeded: isExceeded,
        helpText: _helpText
    }
}

export interface FoodCourseDiscriminationResult {
    isExceeded: boolean;
}

export const discriminateFoodCourse = function (id: number, state: ApplicationState): FoodCourseDiscriminationResult {

    let discriminationRules = state.discrimination.configuration.rules;
    let portionCounters = state.discrimination.rulePortionCounter;
    let typeCounters = state.discrimination.ruleTypeCounter;
    let courseGroupSets = state.discrimination.courseGroupSets;
    let isExceeded: boolean = false;

    let foodCourseToExclude = foodCourseGroupToExcludeSelector(state);
    foodCourseToExclude.forEach(foodCourse => {
        isExceeded = state.menu.menu.courses[id].courseGroups.some(courseGroup => {
            return foodCourse === courseGroup;

        })
        if (isExceeded) {
            return {
                isExceeded: true,
            }
        }
    })

    foodCourseToExclude = foodCourseGroupOverDiscriminationPortionSelector(state);
    isExceeded = foodCourseToExclude.some(foodCourse => {
        return state.menu.menu.courses[id].courseGroups.some(courseGroup => {
            if (state.menu.menu.courseGroupKeys.indexOf(courseGroup) > -1) {                
                //let setNr = state.menu.menu.courseGroups[courseGroup].discriminationSetNumber;                    
                if (foodCourse === courseGroup) {
                    //1 courseGroup can be in more sets
                    courseGroupSets[courseGroup].forEach(setNr => {
                        if (discriminationRules[setNr].maxima) {
                            if (discriminationRules[setNr].maxima.maximumPortions < portionCounters[setNr] || (discriminationRules[setNr].maxima.maximumPortions === 0 && portionCounters[setNr] > 0)) {
                                return true;
                            }
                        }                        
                    });
                }
            }
        })
    })

    if (isExceeded) {
        return {
            isExceeded: true,
        }
    }

    foodCourseToExclude = foodCourseGroupOverDiscriminationTypeSelector(state);
    isExceeded = foodCourseToExclude.some(foodCourse => {
        return state.menu.menu.courses[id].courseGroups.some(courseGroup => {
            if (state.menu.menu.courseGroupKeys.indexOf(courseGroup) > -1) {              
                //let setNr = state.menu.menu.courseGroups[courseGroup].discriminationSetNumber;
                if (foodCourse === courseGroup) {
                    //1 courseGroup can be in more sets
                    courseGroupSets[courseGroup].forEach(setNr => {
                        if (discriminationRules[setNr].maxima) {
                            if (discriminationRules[setNr].maxima.maximumTypes < typeCounters[setNr] || (discriminationRules[setNr].maxima.maximumTypes === 0 && typeCounters[setNr] > 0)) {
                                return true;
                            }
                        }                        
                    });
                }                
            }
        })
    })

    if (isExceeded) {
        return {
            isExceeded: true,
        }
    }

    let dependencyFoodCoursesToExclude = foodCourseGroupOverDependencyPortionSelector(state);
    let _found = false;

    isExceeded = dependencyFoodCoursesToExclude.some(dependencyFoodCourse => {
        return dependencyFoodCourse.components.some(foodCourseGroup => {
            return state.menu.menu.courses[id].courseGroups.some(courseGroup => {
                if (foodCourseGroup === courseGroup) {
                    if (dependencyFoodCourse.isExceeded && !_found) {
                        return true;
                    }
                }
            })
        })
    })


    return {
        isExceeded,
    }
}