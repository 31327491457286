import {useTheme} from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import styles from "./Header.module.css";
import {Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import Taal from "@mui/icons-material/Language";
import Box from '@mui/material/Box'
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';

interface AppBarComponentProps {
    showReturnIcon: boolean
    showMenuIcon: boolean
    isTranslationActive: boolean
    setOpenLanguages: (openLanguages: boolean) => void
    setOpenMenu: (openMenu: boolean) => void
}

const AppBarComponent: React.FC<AppBarComponentProps> = ({
                                                             showReturnIcon,
                                                             showMenuIcon,
                                                             isTranslationActive,
                                                             setOpenMenu,
                                                             setOpenLanguages
                                                         }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme()

    const handleToggleMenu = (open: boolean) => () => {
        setOpenMenu(open)
    };

    const handleToggleLanguage = (open: boolean) => {
        setOpenLanguages(open)
    };

    return <AppBar position="static" sx={{backgroundColor: theme => theme.topHeader.main}}>
        <Toolbar className={styles.toolbar}>
            <Box ml='auto'>
                    <span className={styles.headerVisual}>
                        <Box component={'img'} id={styles.logo} src={process.env.PUBLIC_URL + theme.logo}/>
                        <Typography variant="h6">C-Meal</Typography>
                    </span>
            </Box>
            <Box ml='auto' display='flex' alignItems='flex-start'>
                <IconButton id={styles.returnIcon} aria-label={t('profiel.Terug')} onClick={() => navigate('/menu')}
                            style={{display: showReturnIcon ? 'block' : 'none'}}>
                    <ArrowBack sx={{verticalAlign: 'middle'}}/>
                </IconButton>
                <Box display='flex'>
                    <IconButton id={styles.logoutIcon} aria-label={t('profiel.Open_menu')}
                                onClick={handleToggleMenu(true)}
                                style={{display: showMenuIcon ? 'block' : 'none'}}
                                sx={{p: 0, ml: '-10px'}}>
                        <MenuIcon sx={{verticalAlign: 'middle'}}/>
                    </IconButton>
                    <IconButton id={styles.languageIcon}
                                style={{display: (!showReturnIcon && isTranslationActive) ? 'block' : 'none'}}
                                sx={{
                                    p: 0,
                                    ml: '12px'
                                }} onClick={() => handleToggleLanguage(true)}>
                        {/*<img src={Taal} style={{ "maxWidth": "90%" }} />*/}
                        <Taal sx={{verticalAlign: 'middle'}}/>
                    </IconButton>
                </Box>
            </Box>
        </Toolbar>
    </AppBar>;
}

export default AppBarComponent