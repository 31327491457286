import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";

interface AboutDialogProps {
    openAbout: boolean
    setOpenAbout: (openAbout: boolean) => void
    setOpenMenu: (open: boolean) => void
    clientVersion: string
    backendVersion: string
}

const AboutDialog: React.FC<AboutDialogProps> = ({ openAbout, setOpenAbout, clientVersion, backendVersion, setOpenMenu }) => {
    const {t} = useTranslation();

    const handleCloseAbout = () => {
        setOpenMenu(false)
        setOpenAbout(false)
    };

    return <Dialog
        open={openAbout}
        onClose={handleCloseAbout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <Box>Product Version: {t(clientVersion)}</Box>
                <Box>Backend Version: {t(backendVersion)}</Box>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseAbout} color="primary" autoFocus>
                {t("algemeen.Ok")}
            </Button>
        </DialogActions>
    </Dialog>
}

export default AboutDialog