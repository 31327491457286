import { orderAction } from "./OrderActions";
import { OrderState, initialOrderState } from "./OrderTypes";
import { MealMoment, MenuType,SelectMenuOptions } from "./OrderTypes";
import { Menu } from '../menu/MenuTypes';
import { menuAction } from '../menu/MenuActions';
 

export const orderReducer = (state: OrderState = initialOrderState, action: any): OrderState => {
    switch (action.type) {
        case menuAction.RECEIVE_MENU:
            return handleReceiveMenu(state, action.payload);
        case menuAction.ERROR_RECEIVE_MENU:
            return handleErrorReceiveMenu(state);
        case orderAction.SELECT_MEAL_MOMENT:
            return handleSelectMealMoment(state, action.payload);
        case orderAction.SELECT_MENU:
            return handleSelectMenu(state, action.payload as SelectMenuOptions);
        case orderAction.SELECT_COURSE_PANEL:
            return handleSelectCoursePanel(state, action.payload);
        case orderAction.GO_NEXT_STEP:
            return handleGoNextStep(state, action.payload);
        case orderAction.CHANGE_PORTION:
            return handleUpdatePortion(state, action.payload);
        case orderAction.CONFIRM_MENU:
            return handleConfirmMenu(state);
        case orderAction.CONFIRM_NOMEAL:
            return handleConfirmNoMeal(state);
        case orderAction.SHOW_SNACKBAR:
            return handleShowSnackbar(state, action.payload);
        case orderAction.SHOW_CUSTOM_DIALOG:
            return handleShowCustomDialog(state, action.payload);
        case orderAction.SHOW_FOOD_INFORMATION_DIALOG:
            return handleShowFoodInformationDialog(state, action.payload);
        case orderAction.CLOSE_SNACKBAR:
            return handleCloseSnackbar(state);
        case orderAction.CLOSE_CUSTOM_DIALOG:
            return handleCloseCustomDialog(state);
        case orderAction.CLOSE_FOOD_INFORMATION_DIALOG:
            return handleCloseFoodInformationDialog(state);
        case orderAction.CLEAR_ORDER:
            return handleClearOrder(state);
        case orderAction.SET_ORDER_DAILY_MENU:
            return handleSetOrderDailyMenu(state, action.payload);
        case orderAction.SET_ORDER_EXTRAS:
            return handleSetOrderExtraFoodItems(state, action.payload);
        case orderAction.SET_OVERVIEW_PANEL:
            return handleSetOverviewPanel(state, action.payload);
        case orderAction.RECEIVE_FOOD_INFORMATION_DIALOG_IMAGE:
            return handleReceiveFoodInforamtionDialogImage(state, action.payload);
        default:
            return state;
    }
}

const handleReceiveMenu = (state: OrderState, payload: Menu) => {
    return {
        ...state,
        menuType: 0,
        itemPortions: { ...payload.dailyMenu }
    }
}


const handleErrorReceiveMenu = (state: OrderState): OrderState => {
    return {
        ...initialOrderState,
        mealMoment: state.mealMoment,
        menuType: state.menuType,
        itemPortions: {},
        foodItemsExtra: {}
    }
}


const handleSelectMealMoment = (state: OrderState, payload: MealMoment) => {
    if (!payload.toggle && !payload.blocked) { //if mealmoment was disabled return original state
        return {
            ...state,
        }
    }
    return {
        ...state,
        mealMoment: payload,
    }
}

const handleSelectMenu = (state: OrderState, payload: SelectMenuOptions) => {
    var newState = {
        ...state,
        menuType: payload.menuType
    }
 
    if (payload.focusCourse > 0) {
        let newValue = payload.focusCourse;
        let toggleCurrentCourse = state.menuType === payload.menuType && newState.expandedCoursePanel === newValue;
        if (toggleCurrentCourse) {
            newValue = -1;
        }
        newState.expandedCoursePanel = newValue;
    } else {
        newState.expandedCoursePanel = -1;
    }
    return newState;
}

const handleSelectCoursePanel = (state: OrderState, payload: any) => {
    if (!payload.expanded) { //if panel is being closed set expandedCoursePanel to -1
        payload.id = -1;
    }
    return {
        ...state,
        menuType : MenuType.CUSTOM_MENU,
        expandedCoursePanel: payload.id,
    }
}

const handleGoNextStep = (state: OrderState, payload: number) => {
    return {
        ...state,
        expandedCoursePanel: payload,
    }
}

const handleUpdatePortion = (state: OrderState, payload: any) => {
    let id = payload.id;
    let menuChartLineNr = payload.menuChartLineNr;
    let newPortion = payload.newPortion;
    let myItemPortions = { ...state.itemPortions };

    myItemPortions[id] = {
        foodItemID: id,
        menuChartLineNr: menuChartLineNr,
        portion: newPortion,
    };

    if (myItemPortions[id].portion === 0) { //if portion is reduced to zero, remove it from our order
        delete myItemPortions[id];
    }

    return {
        ...state,
        isDirty: true,
        itemPortions: myItemPortions,
    };
}

const handleConfirmMenu = (state: OrderState) => {
    return {
        ...initialOrderState,
        mealMoment: state.mealMoment
    }
}

const handleConfirmNoMeal = (state: OrderState) => {

    return {
        ...initialOrderState,
        mealMoment: state.mealMoment
    }
}

const handleClearOrder = (state: OrderState) => {
    return {
        ...initialOrderState,
        snackbar: { ...state.snackbar },
    }
}

const handleShowSnackbar = (state: OrderState, payload: any): OrderState => {
    if (!payload.text) {
        return {
            ...state
        };
    }
    return {
        ...state,
        snackbar: {
            show: true,
            text: payload.text,
            variant: payload.variant,
            duration: payload.duration,
            textAlign: payload.textAlign
        }
    }
}

const handleShowCustomDialog = (state: OrderState, payload: any): OrderState => {
    if (!payload.title) {
        return {
            ...state
        };
    }
    return {
        ...state,
        customDialog: {
            show: true,
            title: payload.title,
            content: payload.content,
            buttonText: payload.buttonText,
        }
    }
}

const handleShowFoodInformationDialog = (state: OrderState, payload: any): OrderState => {
    return {
        ...state,
        foodInformationDialog: {
            show: true,
            description: payload.description,
            allergens: payload.allergens,
            nutrients: payload.nutrients,
            url_image: payload.url_image,
            detailedDescription: payload.detailedDescription
        }
    }
}

function convertToObjectUrl(data: any, contentType: string) {
    //data has to be Blob, File or MediaSource object, not data itself
    var binaryData = [];
    binaryData.push(data);
    return window.URL.createObjectURL(new Blob(binaryData, { type: contentType }))
}

const handleReceiveFoodInforamtionDialogImage = (state: OrderState, payload: any): OrderState => {
    let imageURL = convertToObjectUrl(payload.data, payload.contentType);
    return {
        ...state,
        foodInformationDialog: {
            ...state.foodInformationDialog,
            url_image: imageURL,
        }
    }
}


const handleCloseSnackbar = (state: OrderState) => {
    return {
        ...state,
        snackbar: {
            ...state.snackbar,
            show: false,
            text: '',
        }
    }
}

const handleCloseCustomDialog= (state: OrderState) => {
    return {
        ...state,
        customDialog: {
            ...state.customDialog,
            show: false,
            title: '',
            content: '',
            buttonText: '',
        }
    }
}

const handleCloseFoodInformationDialog = (state: OrderState) => {
    return {
        ...state,
        foodInformationDialog: {
            ...state.foodInformationDialog,
            show: false,
        }
    }
}

const handleSetOrderDailyMenu = (state: OrderState, payload: any) => {
    return {
        ...state,
        itemPortions: payload,
    }
}

const handleSetOrderExtraFoodItems = (state: OrderState, payload: any) => {
    return {
        ...state,
        foodItemsExtra: payload.data,
    }
}

const handleSetOverviewPanel = (state: OrderState, payload: boolean) => {
    return {
        ...state,
        overviewPanelOpen: payload,
    }
}