import * as React from "react";
import InformationHeaderComponent from "./InformationHeaderComponent";
import {MenuType} from "../../modules/order/OrderTypes";
import TabMenuComponent from "./TabMenuComponent";
import {useNavigate} from "react-router-dom";
import FoodInformationDialogComponent from "./FoodInfoDialog";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {closeFoodInformationDialog, selectMenu} from "../../modules/order/OrderActions";
import Prompt from "../../hooks/Prompt";

const OrderViewComponent: React.FC = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const message = t('order.DmenuOpm');

    const myMenuState = useSelector((state: ApplicationState) => state.menu)
    const myOrderState = useSelector((state: ApplicationState) => state.order)
    const myConfigState = useSelector((state: ApplicationState) => state.config)
    const featureMealPictures = myConfigState.mealPictures;
    const featureIsDietOptionVisible = myConfigState.showDiet


    const mealMoment = myOrderState.mealMoment === null ? undefined : myOrderState.mealMoment
    const menuType = myOrderState.mealMoment === null ? MenuType.DAILY_MENU : myOrderState.menuType
    const foodItemDialog = myOrderState.mealMoment === null ? {
        show: false,
        allergens: null,
        nutrients: null,
        description: "",
        url_image: "",
        detailedDescription: ""
    } : myOrderState.foodInformationDialog
    const isDirty = myOrderState.isDirty
    const foodItems = myOrderState.mealMoment === null ? null : myMenuState.menu.foodItems
    const foodItemsPortions  = myOrderState.mealMoment === null ? null : myOrderState.itemPortions
    const foodItemsExtra = myOrderState.mealMoment === null ? null : myOrderState.foodItemsExtra



    const changeMenu = (event: any, tabIndex: any) => {
        let menuType = tabIndex as MenuType;
        selectMenu(menuType)(dispatch, myMenuState)
    }
    const closeDialog = () => {
        dispatch(closeFoodInformationDialog());
    }

    React.useEffect(() => {
        if (!mealMoment) {
            navigate('/')
        }
    }, [mealMoment])


    const mealMomentDate = new Date(mealMoment.date);
    const dietDescription = featureIsDietOptionVisible && mealMoment.diet.dietDescription;

    return <>
        <FoodInformationDialogComponent closeDialog={closeDialog} featureMealPictures={featureMealPictures}
                                        foodItemDialog={foodItemDialog}/>
        <InformationHeaderComponent title={mealMoment.title} date={mealMomentDate}/>
        <TabMenuComponent
            firstTitle={mealMoment.isOrdered ? t('order.Ukeuze') : mealMoment.isBBFSelectable ? t('order.Ukeuze') : t('order.Mvd')} // || this.props.mealMoment.isNoMeal
            secondTitle={mealMoment.isOrdered ? t('order.DmenuPa') : t('order.DmenuSs')}
            isDirty={isDirty}
            tabChanger={changeMenu}
            activeTab={menuType}
            information={mealMoment.description.toLowerCase()}
            dietDescription={dietDescription}
            mealMomentIsBlocked={mealMoment.blocked}
            isBBF={mealMoment.isBBF}
            isBBFReadonly={!mealMoment.isBBFSelectable}
            foodItems={foodItems}
            foodItemsPortions={foodItemsPortions}
            foodItemsExtra={foodItemsExtra}
            noMeal={mealMoment.isNoMeal}
        />
        <Prompt when={isDirty} message={message} />
    </>
}

export default OrderViewComponent

