import React from 'react'
import {useTranslation} from 'react-i18next';
import axios from "axios"
import IconButton from '@mui/material/IconButton';
import styles from "./HelpComponent.module.css";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ConfirmDialog from "../dialog/ConfirmationDialog";
import Box from "@mui/material/Box";

interface HelpComponentProps {
    code: string
}
const HelpComponent: React.FC<HelpComponentProps> = ({code}) => {
    const {t, i18n} = useTranslation()
    const [isOpen, setIsOpen] = React.useState(false)
    const [description, setDescription] = React.useState<string>('help.Geenhelp')

    const handleClickOpenPatientNumberInfo = () => {
        axios.get<string>("/api/hospital/helptip?code=" + code + "&culture=" + i18n.language)
            .then(response => {
                const tip = response.data;
                const descriptionString = tip.indexOf("|tfrsc") < 0 ? tip : t("resource." + tip)
                setDescription(descriptionString)
                setIsOpen(true)
            })
    }

    return (
        <Box>
            <IconButton aria-label="info" className={styles.infoIcon} onClick={handleClickOpenPatientNumberInfo}>
                <InfoIcon/>
            </IconButton>
            <ConfirmDialog open={isOpen} handleClose={() => setIsOpen(false)} closeText={'Ok'} title={'Tip'} message={t(description)} />
        </Box>
    )
}

export default HelpComponent
